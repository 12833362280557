import React, { useEffect, useState } from 'react';
import { getBasicData, getInvocationSeries } from './network';
import find from 'lodash/find';
import { PieCharts } from 'components/piechart';
import { LineChart } from 'components/linechart';
import Loading from 'components/loading/Loading';
import { DropdownMenus } from 'components/dropdown';
import { InvocationTables } from './invocationTables';

export const AppDashboard = () => {
  const colors = ['#001840', '#ffbd59', '#3d506f', '#D0C0AE', '#FF8B00', '#000000'];
  const seriesDateOptions = [
    { value: 1, label: 'Last 1 month' },
    { value: 3, label: 'Last 3 month' },
    { value: 6, label: 'Last 6 month' }
  ];

  const lineChartConfig = {
    slackTrigger: {
      label: 'Slack Trigger',
      color: '#ffbd59'
    },
    cronTrigger: {
      label: 'Cron Trigger',
      color: '#EBE3D9'
    },
    webhookTrigger: {
      label: 'Webhook Trigger',
      color: '#001840'
    }
  };

  const [commandChartData, setCommandChartData] = useState([]);
  const [connectionChartData, setConnectionChartData] = useState([]);
  const [userChartData, setUserChartData] = useState([]);

  const [series, setSeries] = useState([]);
  const [selectedSeriesDateFilter, setSelectedSeriesDateFilter] = useState(seriesDateOptions[0]);
  const [dateBoundaries, setDateBoundaries] = useState(
    getLastNMonthsDates(selectedSeriesDateFilter.value)
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBasicData()
      .then(({ success, payload }) => {
        if (success) {
          const commandBasicData = find(payload.basicData, { kind: 'command' });
          const connectionBasicData = find(payload.basicData, { kind: 'connection' });
          const userBasicData = find(payload.basicData, { kind: 'user' });

          const formattedCommandData = commandBasicData?.metadata.map((item, index) => ({
            browser: item.commandKind,
            count: item.count,
            fill: colors[index % colors.length]
          }));
          setCommandChartData(formattedCommandData);

          const formattedConnectionData = connectionBasicData?.metadata.map((item, index) => ({
            browser: item.connectionKind,
            count: item.count,
            fill: colors[index % colors.length]
          }));
          setConnectionChartData(formattedConnectionData);

          const formattedUserData = userBasicData?.metadata.map((item, index) => ({
            browser: item.userRole,
            count: item.count,
            fill: colors[index % colors.length]
          }));
          setUserChartData(formattedUserData);
        }
      })
      .catch((e) => {
        console.log('Something went wrong...');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const dateBoundaries = getLastNMonthsDates(selectedSeriesDateFilter.value);
    setDateBoundaries(dateBoundaries);
    getInvocationSeries({
      startDate: dateBoundaries.startDate,
      endDate: dateBoundaries.endDate,
      direction: 'desc'
    })
      .then(({ success, payload }) => {
        if (success) {
          const seriesData = [];
          const dates = [
            ...new Set([
              ...payload.invocationData.slackTrigger.map((item) => item[0]),
              ...payload.invocationData.cronTrigger.map((item) => item[0]),
              ...payload.invocationData.webhookTrigger.map((item) => item[0])
            ])
          ];

          dates.forEach((date) => {
            const slackData =
              payload.invocationData.slackTrigger.find((item) => item[0] === date)?.[1] || '0';
            const cronData =
              payload.invocationData.cronTrigger.find((item) => item[0] === date)?.[1] || '0';
            const webhookData =
              payload.invocationData.webhookTrigger.find((item) => item[0] === date)?.[1] || '0';

            seriesData.push({
              date: new Date(date).toLocaleDateString('tr-TR'),
              slackTrigger: parseInt(slackData, 10),
              cronTrigger: parseInt(cronData, 10),
              webhookTrigger: parseInt(webhookData, 10)
            });
          });

          setSeries(seriesData);
        }
      })
      .catch((e) => {
        console.log('Something went wrong...');
      });
  }, [selectedSeriesDateFilter.value]);

  function getLastNMonthsDates(n) {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - n);

    const dateOneMonthAgo = new Date(currentDate);

    const formattedStartDate = dateOneMonthAgo.toISOString().slice(0, 10).replace(/-/g, '');
    const formattedEndDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');

    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };
  }

  if (loading) {
    <Loading />;
  }
  return (
    <section className="flex flex-col lg:flex-row w-full gap-4 h-screen">
      <div className="flex w-full flex-col gap-4 lg:w-2/3">
        <div>
          <DropdownMenus
            label={'Select Date'}
            options={seriesDateOptions}
            onChange={(selectedOption) => {
              setSelectedSeriesDateFilter(selectedOption);
            }}
          />
        </div>
        <div className="grid grid-col-1 lg:grid-cols-3 gap-2">
          <PieCharts chartData={commandChartData} title="Commands" />
          <PieCharts chartData={connectionChartData} title="Connections" />
          <PieCharts chartData={userChartData} title="Users" />
        </div>

        <div>
          <LineChart
            selectedDate={selectedSeriesDateFilter.label}
            chartData={series}
            chartConfig={lineChartConfig}
          />
        </div>
      </div>
      <div className="flex w-full flex-col gap-4 lg:w-1/3 overflow-y-auto">
        <InvocationTables kind="command" header="Command" dateBoundaries={dateBoundaries} />
        <InvocationTables kind="connection" header="Connection" dateBoundaries={dateBoundaries} />
        <InvocationTables kind="user" header="User" dateBoundaries={dateBoundaries} />
      </div>
    </section>
  );
};
