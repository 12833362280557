import SettingsMain from 'components/settings/Settings';
import CommandList from 'features/command/commandList';
import CommandNew from 'features/command/commandNew';
import CommandNewAws from 'features/command/commandNew/CommandNewAws';
import CommandNewRest from 'features/command/commandNew/CommandNewRest';
import CommandNewSql from 'features/command/commandNew/CommandNewSql';
import ConnectionList from 'features/connection/connectionList';
import ConnectionNew from 'features/connection/connectionNew';
import ConnectionNewAgent from 'features/connection/connectionNew/ConnectionNewAgent';
import ConnectionNewAws from 'features/connection/connectionNew/ConnectionNewAws';
import ConnectionNewRest from 'features/connection/connectionNew/ConnectionNewRest';
import ConnectionNewSql from 'features/connection/connectionNew/ConnectionNewSql';
import DashboardMainLogout from 'features/dashboard/main/DashboardMainLogout';
import NotFound from 'features/errorPages/NotFound';
import IncidentsMain from 'features/incident/Incident';
import IntegrationNew from 'features/integration/IntegrationNew';
import IntegrationsMain from 'features/integration/IntegrationsMain';
import KeyListSsh from 'features/key/KeyList';
import KeyNew from 'features/key/KeyNew';
import KeyNewCredential from 'features/key/KeyNew/KeyNewCredential';
import KeyNewAws from 'features/key/KeyNew/keyNewAws';
import KeyNewBearer from 'features/key/KeyNew/keyNewBearer';
import KeyNewRest from 'features/key/KeyNew/keyNewRest';
import KeyNewRestTokenAuth from 'features/key/KeyNew/keyNewRestTokenAuth';
import KeyNewSqlCredential from 'features/key/KeyNew/keyNewSqlCredential';
import KeyNewSshCredential from 'features/key/KeyNew/keyNewSshCredential';
import Layout from 'features/layout/Layout';
import Logs from 'features/log/AuditLogs';
import ForgotPassword from 'features/login/ForgotPassword';
import LoginActivationMain from 'features/login/LoginActivationMain';
import LoginFooter from 'features/login/LoginFooter';
import LoginMain from 'features/login/LoginMain';
import LoginWrapper from 'features/login/LoginWrapper';
import Register from 'features/login/Register';
import ResetPassword from 'features/login/ResetPassword';
import Signup from 'features/login/Signup';
import Pricing from 'features/pricing';
import ProjectEditBody from 'features/project/ProjectEditBody';
import ProjectList from 'features/project/ProjectList';
import ProjectNew from 'features/project/ProjectNew';
import ApprovalPolicies from 'features/settings/ApprovalPolicies';
import PolicyList from 'features/settings/ExecutionPolicies/PolicyList';
import PoliciesNew from 'features/settings/ExecutionPolicies/PolicyNew';
import GroupNew from 'features/settings/Groups/GroupNew';
import GroupList from 'features/settings/Groups/GroupsList';
import { StripeSuccess } from 'features/stripe/success';
import SelectTrigger from 'features/triggers/SelectTrigger';
import CronEditBody from 'features/triggers/TriggerEditBody/CronEditBody';
import WebhookEditBody from 'features/triggers/TriggerEditBody/WebhookEditBody';
import TriggerCronList from 'features/triggers/TriggerList/CronList';
import TriggerWebhookList from 'features/triggers/TriggerList/WebhookList';
import CronNew from 'features/triggers/TriggerNew/CronNew';
import WebhookNew from 'features/triggers/TriggerNew/WebhookNew';
import NewUser from 'features/user/NewUser';
import RegisterUserRole from 'features/user/RegisterUserRole';
import UserContent from 'features/user/UserContent';
import WorkflowList from 'features/workflow/WorkflowList';
import React from 'react';
import WorkflowNew from 'features/workflow/WorkflowNew';
import { StripeCancel } from 'features/stripe/cancel';
import { Apps } from 'features/apps';
import { AppDetail } from 'features/apps/appDetail';
import { AppDashboard } from 'features/appDashboard';
import CommandNewJira from 'features/command/commandNew/CommandNewJira';
import ConnectionNewJira from 'features/connection/connectionNew/ConnectionNewJira';
import KeyNewJiraCredential from 'features/key/KeyNew/KeyNewJiraCredential';
import EventLogs from 'features/eventLogs/EventLogs';
import KeyNewCms from 'features/key/KeyNew/KeyNewCms';

import { AppNew } from 'features/apps/appNew';

import KeyNewAwsRoleBaseAuth from 'features/key/KeyNew/KeyNewAwsRoleBaseAuth';

import { AppEdit } from 'features/apps/appEdit';

import ConnectionNewSsm from 'features/connection/connectionNew/ConnectionNewSsm';
import AuditLogsDetail from 'features/log/AuditLogsDetail';
import { ProfileLayout } from 'features/profileLayout';
import { Account } from 'features/account';
import { AppDetailLayout } from 'appDetailLayout';
import CommandNewScript from 'features/command/commandNew/CommandNewScript';
import { EnvironmentVariables } from 'features/settings/environmentVariables';
import { NewEnvironmentVariableForm } from 'features/settings/environmentVariables/newEnvironmentVariableForm';
import { EditEnvironmentVariableForm } from 'features/settings/environmentVariables/editEnvironmentVariableForm';
import { FileStorage } from 'features/fileStorage';
import { NewFileForm } from 'features/fileStorage/newFileForm';
import TriggerLinkList from 'features/triggers/TriggerList/LinkList';
import LinkNew from 'features/triggers/TriggerNew/LinkNew';
import LinkEditBody from 'features/triggers/TriggerEditBody/LinkEditBody';
import WorkspaceSettings from 'features/settings/WorkspaceSettings';
import Debugger from 'features/debugger';
import ExecutionCommand from 'features/executionCommand/ExecutionCommand';

export const roleMap = new Map([
  ['read-only', 1],
  ['operator', 2],
  ['admin', 3],
  ['superadmin', 4]
]);

export const planMap = new Map([
  ['none', 0],
  ['basic', 1],
  ['pro', 2],
  ['enterprise', 3],
  ['startup', 4]
]);

export const routesWithRoles = {
  root: {
    path: '',
    element: <LoginMain />,
    role: 'read-only'
  },
  notFound: {
    path: '/notFound',
    element: <NotFound />,
    role: 'read-only'
  },
  signup: {
    path: '/signup',
    element: <Signup />,
    role: 'read-only'
  },
  register: {
    path: '/register/:email/:token/:lookupKey/:trial',
    element: <Register />,
    role: 'read-only'
  },
  slackAuth: {
    path: '/slack-auth',
    element: <LoginMain />,
    role: 'read-only'
  },
  workSpaceRegisterSlackAuth: {
    path: 'workspace-register-slack-auth',
    element: <RegisterUserRole />,
    role: 'read-only'
  },
  forgotPassword: {
    path: 'forgotpassword',
    element: <ForgotPassword />,
    role: 'read-only'
  },
  resetPassword: {
    path: 'resetpassword/:email/:token',
    element: <ResetPassword />,
    role: 'read-only'
  },
  workspaceRegister: {
    path: 'workspace-user-register/:email/:token/',
    element: <RegisterUserRole />,
    role: 'read-only'
  },
  pricing: {
    path: 'pricing',
    element: <Pricing />,
    role: 'superadmin'
  },
  activate: {
    path: 'activate',
    element: (
      <LoginWrapper>
        <LoginActivationMain />
        <LoginFooter />
      </LoginWrapper>
    ),
    role: 'read-only'
  },
  logout: {
    path: 'logout',
    element: <DashboardMainLogout />,
    role: 'read-only',
    plan: 'none'
  },
  layout: {
    path: '/',
    element: <Layout />,
    plan: 'basic',
    children: {
      incidents: {
        path: 'incidents',
        element: <IncidentsMain />,
        role: 'read-only',
        plan: 'basic'
      },
      apps: {
        path: 'apps',
        element: <Apps />,
        role: 'read-only',
        plan: 'enterprise'
      },
      appNew: {
        path: 'apps/new',
        element: <AppNew />,
        role: 'admin',
        plan: 'enterprise'
      },

      appDetailLayout: {
        path: '',
        element: <AppDetailLayout />,
        role: 'read-only',
        plan: 'enterprise',
        children: {
          appDetail: {
            path: 'apps/:id',
            element: <AppDetail />,
            role: 'read-only',
            plan: 'enterprise'
          },
          appEdit: {
            path: 'apps/:id/edit',
            element: <AppEdit />,
            role: 'read-only',
            plan: 'enterprise'
          }
        }
      },

      logs: {
        path: 'audit-logs',
        element: <Logs />,
        role: 'read-only',
        plan: 'pro'
      },
      logsEdit: {
        path: 'audit-logs/:id',
        element: <AuditLogsDetail />,
        role: 'read-only',
        plan: 'pro'
      },
      eventLogs: {
        path: 'event-logs',
        element: <EventLogs />,
        role: 'read-only',
        plan: 'pro'
      },
      debugger: {
        path: 'debugger',
        element: <Debugger />,
        role: 'read-only',
        plan: 'pro'
      },
      appDashboard: {
        path: 'dashboard',
        element: <AppDashboard />,
        role: 'read-only',
        plan: 'basic'
      },
      triggerSlackList: {
        path: 'projects',
        element: <ProjectList />,
        role: 'read-only',
        plan: 'basic'
      },
      triggerSlackNew: {
        path: 'projects/new',
        element: <ProjectNew />,
        role: 'operator',
        plan: 'basic'
      },
      triggerSlackEdit: {
        path: 'projects/:projectId',
        element: <ProjectEditBody />,
        role: 'read-only',
        plan: 'basic'
      },
      commandList: {
        path: 'commands',
        element: <CommandList selected="all" />,
        role: 'read-only',
        plan: 'basic'
      },
      commandSshList: {
        path: 'commands/ssh',
        element: <CommandList selected="ssh" />,
        role: 'read-only',
        plan: 'basic'
      },
      commandRestList: {
        path: 'commands/rest',
        element: <CommandList selected="rest" />,
        role: 'read-only',
        plan: 'basic'
      },
      commandAwsList: {
        path: 'commands/aws',
        element: <CommandList selected="aws" />,
        role: 'read-only',
        plan: 'basic'
      },
      commandSqlList: {
        path: 'commands/sql',
        element: <CommandList selected="sql" />,
        role: 'read-only',
        plan: 'basic'
      },
      commandSafeList: {
        path: 'commands/safe',
        element: <CommandList selected="rest" />,
        role: 'read-only',
        plan: 'basic'
      },
      commandJiraList: {
        path: 'commands/jira',
        element: <CommandList selected="jira" />,
        role: 'read-only',
        plan: 'basic'
      },
      triggers: {
        path: 'triggers',
        element: <SelectTrigger />,
        role: 'read-only',
        plan: 'basic'
      },
      triggerWebhookList: {
        path: 'triggers/webhook',
        element: <TriggerWebhookList />,
        role: 'read-only',
        plan: 'pro'
      },
      triggerWebhookNew: {
        path: 'triggers/webhook/new',
        element: <WebhookNew />,
        role: 'operator',
        plan: 'pro'
      },
      triggerWebhookEdit: {
        path: 'triggers/webhook/:webhookName',
        element: <WebhookEditBody />,
        role: 'read-only',
        plan: 'pro'
      },
      triggerLinkList: {
        path: 'triggers/link',
        element: <TriggerLinkList />,
        role: 'read-only',
        plan: 'pro'
      },
      triggerLinkNew: {
        path: 'triggers/link/new',
        element: <LinkNew />,
        role: 'operator',
        plan: 'pro'
      },
      triggerLinkEdit: {
        path: 'triggers/link/:linkName',
        element: <LinkEditBody />,
        role: 'read-only',
        plan: 'pro'
      },
      triggerCronList: {
        path: 'triggers/cron',
        element: <TriggerCronList />,
        role: 'read-only',
        plan: 'pro'
      },
      triggerCronNew: {
        path: 'triggers/cron/new',
        element: <CronNew />,
        role: 'operator',
        plan: 'pro'
      },
      triggerCronEdit: {
        path: 'triggers/cron/:cronName',
        element: <CronEditBody />,
        role: 'read-only',
        plan: 'pro'
      },
      connectionList: {
        path: 'connections',
        element: <ConnectionList selected="all" />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionSshList: {
        path: 'connections/ssh',
        element: <ConnectionList selected="ssh" />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionRestList: {
        path: 'connections/rest',
        element: <ConnectionList selected="rest" />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionAwsList: {
        path: 'connections/aws',
        element: <ConnectionList selected="aws" />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionSqlList: {
        path: 'connections/sql',
        element: <ConnectionList selected="sql" />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionSafeList: {
        path: 'connections/safe',
        element: <ConnectionList selected="safe" />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionAgentList: {
        path: 'connections/agent',
        element: <ConnectionList selected="agent" />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionJiraList: {
        path: 'connections/jira',
        element: <ConnectionList selected="jira" />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionSsmList: {
        path: 'connections/ssm',
        element: <ConnectionList selected="ssm" />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationList: {
        path: 'integrations',
        element: <IntegrationsMain />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationNew: {
        path: 'integrations/new',
        element: <IntegrationNew />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationSlackList: {
        path: 'integrations/slack',
        element: <ProjectList />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationSshList: {
        path: 'integrations/ssh',
        element: <ProjectList />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationWebhookList: {
        path: 'integrations/webhooks',
        element: <ProjectList />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationEmail: {
        path: 'integrations/email',
        element: <ProjectList />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationJira: {
        path: 'integrations/jira',
        element: <ProjectList />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationStatus: {
        path: 'integrations/statuspage',
        element: <ProjectList />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationOpsgenie: {
        path: 'integrations/opsgenie',
        element: <ProjectList />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationServicedesk: {
        path: 'integrations/servicedesk',
        element: <ProjectList />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationBitbucket: {
        path: 'integrations/bitbucket',
        element: <ProjectList />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationAws: {
        path: 'integrations/aws',
        element: <ProjectList />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationGithub: {
        path: 'integrations/github',
        element: <ProjectList />,
        role: 'read-only',
        plan: 'basic'
      },
      integrationPagerduty: {
        path: 'integrations/pagerduty',
        element: <ProjectList />,
        role: 'read-only',
        plan: 'basic'
      },
      secretList: {
        path: 'secrets',
        element: <KeyListSsh selected="all" />,
        role: 'read-only',
        plan: 'basic'
      },
      secretSshList: {
        path: 'secrets/ssh',
        element: <KeyListSsh selected="ssh" />,
        role: 'read-only',
        plan: 'basic'
      },
      secretRestList: {
        path: 'secrets/rest',
        element: <KeyListSsh selected="rest" />,
        role: 'read-only',
        plan: 'basic'
      },
      secretAwsCredentialList: {
        path: 'secrets/aws',
        element: <KeyListSsh selected="aws-credential" />,
        role: 'read-only',
        plan: 'basic'
      },
      secretSafeList: {
        path: 'secrets/safe',
        element: <KeyListSsh selected="safe" />,
        role: 'read-only',
        plan: 'basic'
      },
      secretOauthList: {
        path: 'secrets/oauth',
        element: <KeyListSsh selected="oauth" />,
        role: 'read-only',
        plan: 'basic'
      },
      secretCredentialList: {
        path: 'secrets/credential',
        element: <KeyListSsh selected="credential" />,
        role: 'read-only',
        plan: 'basic'
      },
      secretSqlCredentialList: {
        path: 'secrets/sql',
        element: <KeyListSsh selected="sql-credential" />,
        role: 'read-only',
        plan: 'basic'
      },
      secretJiraCredentialList: {
        path: 'secrets/jira',
        element: <KeyListSsh selected="jira-credential" />,
        role: 'read-only',
        plan: 'basic'
      },
      settings: {
        path: 'settings',
        element: <SettingsMain />,
        role: 'operator',
        plan: 'basic'
      },
      settingsUser: {
        path: 'settings/users',
        element: <UserContent />,
        role: 'operator',
        plan: 'basic'
      },
      settingsGroup: {
        path: 'settings/group',
        element: <GroupList />,
        role: 'operator',
        plan: 'basic'
      },
      settingsExecutionPolicy: {
        path: 'settings/execution-policies',
        element: <PolicyList />,
        role: 'operator',
        plan: 'basic'
      },
      settingsApprovalPolicy: {
        path: 'settings/approval-policies',
        element: <ApprovalPolicies />,
        role: 'operator',
        plan: 'pro'
      },
      settingsUserNew: {
        path: 'settings/users/new',
        element: <NewUser />,
        role: 'admin',
        plan: 'basic'
      },
      settingsUserEdit: {
        path: 'settings/users/:userMail',
        element: <NewUser />,
        role: 'admin',
        plan: 'basic'
      },
      settingsExecutionPolicyNew: {
        path: 'settings/execution-policies/new',
        element: <PoliciesNew />,
        role: 'admin',
        plan: 'basic'
      },
      settingsExecutionPolicyEdit: {
        path: 'settings/execution-policies/:policyName',
        element: <PoliciesNew />,
        role: 'admin',
        plan: 'basic'
      },
      settingsGroupNew: {
        path: 'settings/group/new',
        element: <GroupNew />,
        role: 'admin',
        plan: 'basic'
      },
      settingsGroupEdit: {
        path: 'settings/group/:groupName',
        element: <GroupNew />,
        role: 'admin',
        plan: 'basic'
      },
      settingsEnvironmentVariables: {
        path: 'settings/environmentVariables',
        element: <EnvironmentVariables />,
        role: 'operator',
        plan: 'basic'
      },
      newEnvironmentVariable: {
        path: 'settings/new/environmentVariable',
        element: <NewEnvironmentVariableForm />,
        role: 'operator',
        plan: 'basic'
      },
      editEnvironmentVariable: {
        path: 'settings/environmentVariable/edit/:envName',
        element: <EditEnvironmentVariableForm />,
        role: 'operator',
        plan: 'basic'
      },
      secretSshNew: {
        path: 'secrets/new/ssh',
        element: <KeyNew />,
        role: 'operator',
        plan: 'basic'
      },
      secretCredentialNew: {
        path: 'secrets/new/credential',
        element: <KeyNewCredential />,
        role: 'operator',
        plan: 'basic'
      },
      secretRestNew: {
        path: 'secrets/new/rest',
        element: <KeyNewRest />,
        role: 'operator',
        plan: 'basic'
      },
      secretAwsCredentialNew: {
        path: 'secrets/new/aws-credential',
        element: <KeyNewAws />,
        role: 'operator',
        plan: 'basic'
      },
      secretCmsCredentialNew: {
        path: 'secrets/new/cms-credential',
        element: <KeyNewCms />,
        role: 'operator',
        plan: 'basic'
      },
      secretBearerTokenNew: {
        path: 'secrets/new/bearer-token',
        element: <KeyNewBearer />,
        role: 'operator',
        plan: 'basic'
      },
      secretSshCredentialNew: {
        path: 'secrets/new/ssh-credential',
        element: <KeyNewSshCredential />,
        role: 'operator',
        plan: 'basic'
      },
      secretSqlCredentialNew: {
        path: 'secrets/new/sql-credential',
        element: <KeyNewSqlCredential />,
        role: 'operator',
        plan: 'basic'
      },
      secretJiraCredentialNew: {
        path: 'secrets/new/jira-credential',
        element: <KeyNewJiraCredential />,
        role: 'operator',
        plan: 'basic'
      },
      secretRestTokenAuthNew: {
        path: 'secrets/new/credential/rest-token-auth/',
        element: <KeyNewRestTokenAuth />,
        role: 'operator',
        plan: 'basic'
      },
      secretAwsRoleBaseAuth: {
        path: 'secrets/new/aws-role-based-auth',
        element: <KeyNewAwsRoleBaseAuth />,
        role: 'operator',
        plan: 'basic'
      },
      commandSshNew: {
        path: 'commands/new/bash',
        element: <CommandNew />,
        role: 'operator',
        plan: 'basic'
      },
      commandSshEdit: {
        path: 'commands/new/bash/:commandName',
        element: <CommandNew />,
        role: 'read-only',
        plan: 'basic'
      },
      commandScripthNew: {
        path: 'commands/new/script',
        element: <CommandNewScript />,
        role: 'operator',
        plan: 'basic'
      },
      commandScriptEdit: {
        path: 'commands/new/script/:commandName',
        element: <CommandNewScript />,
        role: 'read-only',
        plan: 'basic'
      },
      commandSqlNew: {
        path: 'commands/new/sql',
        element: <CommandNewSql />,
        role: 'operator',
        plan: 'basic'
      },
      commandSqlEdit: {
        path: 'commands/new/sql/:commandName',
        element: <CommandNewSql />,
        role: 'read-only',
        plan: 'basic'
      },
      commandRestNew: {
        path: 'commands/new/rest',
        element: <CommandNewRest />,
        role: 'operator',
        plan: 'basic'
      },
      commandRestEdit: {
        path: 'commands/new/rest/:commandName',
        element: <CommandNewRest />,
        role: 'read-only',
        plan: 'basic'
      },
      commandAwsNew: {
        path: 'commands/new/aws',
        element: <CommandNewAws />,
        role: 'operator',
        plan: 'basic'
      },
      commandAwsEdit: {
        path: 'commands/new/aws/:commandName',
        element: <CommandNewAws />,
        role: 'read-only',
        plan: 'basic'
      },
      commandJiraNew: {
        path: 'commands/new/jira',
        element: <CommandNewJira />,
        role: 'operator',
        plan: 'basic'
      },
      commandJiraEdit: {
        path: 'commands/new/jira/:commandName',
        element: <CommandNewJira />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionSshNew: {
        path: 'connections/new/ssh',
        element: <ConnectionNew />,
        role: 'operator',
        plan: 'basic'
      },
      connectionSshEdit: {
        path: 'connections/new/ssh/:connectionName',
        element: <ConnectionNew />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionRestNew: {
        path: 'connections/new/rest',
        element: <ConnectionNewRest />,
        role: 'operator',
        plan: 'basic'
      },
      connectionRestEdit: {
        path: 'connections/new/rest/:connectionName',
        element: <ConnectionNewRest />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionAwsNew: {
        path: 'connections/new/aws',
        element: <ConnectionNewAws />,
        role: 'operator',
        plan: 'basic'
      },
      connectionAwsEdit: {
        path: 'connections/new/aws/:connectionName',
        element: <ConnectionNewAws />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionSqlNew: {
        path: 'connections/new/sql',
        element: <ConnectionNewSql />,
        role: 'operator',
        plan: 'basic'
      },
      connectionSqlEdit: {
        path: 'connections/new/sql/:connectionName',
        element: <ConnectionNewSql />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionAgentNew: {
        path: 'connections/new/agent',
        element: <ConnectionNewAgent />,
        role: 'operator',
        plan: 'basic'
      },
      connectionAgentEdit: {
        path: 'connections/new/agent/:connectionName',
        element: <ConnectionNewAgent />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionJiraNew: {
        path: 'connections/new/jira',
        element: <ConnectionNewJira />,
        role: 'operator',
        plan: 'basic'
      },
      connectionJiraEdit: {
        path: 'connections/new/jira/:connectionName',
        element: <ConnectionNewJira />,
        role: 'read-only',
        plan: 'basic'
      },
      connectionSsmNew: {
        path: 'connections/new/ssm',
        element: <ConnectionNewSsm />,
        role: 'operator',
        plan: 'basic'
      },
      connectionSsmEdit: {
        path: 'connections/new/ssm/:connectionName',
        element: <ConnectionNewSsm />,
        role: 'read-only',
        plan: 'basic'
      },
      stripeSuccess: {
        path: 'stripe/success',
        element: <StripeSuccess />,
        role: 'superadmin',
        plan: 'basic'
      },
      stripeCancel: {
        path: 'stripe/cancel',
        element: <StripeCancel />,
        role: 'superadmin',
        plan: 'basic'
      },
      workflows: {
        // role operator
        path: 'workflows',
        element: <WorkflowList />,
        role: 'read-only',
        plan: 'basic'
      },
      workflowNew: {
        // role operator
        path: 'workflows/new',
        element: <WorkflowNew />,
        role: 'operator',
        plan: 'basic'
      },
      workflowEdit: {
        // role operator
        path: 'workflows/edit/:workflowName',
        element: <WorkflowNew />,
        role: 'read-only',
        plan: 'basic'
      },
      executionCommand: {
        // role operator
        path: '/execution-command',
        element: <ExecutionCommand />,
        role: 'read-only',
        plan: 'basic'
      },
      fileStorage: {
        // role operator
        path: '/file-storage',
        element: <FileStorage />,
        role: 'read-only',
        plan: 'basic'
      },
      newFile: {
        // role operator
        path: '/new/file',
        element: <NewFileForm />,
        role: 'read-only',
        plan: 'basic'
      }
    }
  },
  profileLayout: {
    path: '',
    element: <ProfileLayout />,
    plan: 'basic',
    role: 'read-only',
    children: {
      account: {
        path: '/account',
        element: <Account />,
        role: 'read-only',
        plan: 'basic'
      },
      subscriptionPlan: {
        path: '/subscription-plan',
        element: <Pricing />,
        role: 'superadmin',
        plan: 'basic'
      },
      WorkspaceSettings: {
        path: '/workspace-settings',
        element: <WorkspaceSettings />,
        role: 'superadmin',
        plan: 'basic'
      },
    }
  }
};
