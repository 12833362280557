import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { Spinner } from 'components/Spinner';
import { getEnvironments, postEnvironment } from './network';
import { useParams } from 'react-router-dom';
import { useToast } from 'hooks/use-toast';

export const NewConnectionEnvironmentModal = ({ showModal, setShowModal, setRefresh }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, defaultValues }
  } = useForm({
    defaultValues: {
      kind: false,
      key: '',
      Description: '',
      value: ''
    }
  });
  const params = useParams();
  const [open, setOpen] = useState(showModal);

  const [isLoading, setIsLoading] = useState(false);
  const [uniqueKeyError, setUniqueKeyError] = useState(false);

  const [environmentList, setEnvironmentList] = useState([]);

  const [isSuccess, setIsSuccess] = useState(false);
  const { toast } = useToast();
  useEffect(() => {
    if (showModal) {
      getEnvironments({ connectionName: params.connectionName }).then(
        ({ success, payload }) => {
          if (!success) {
            return;
          }
          const { environments } = payload;
          setEnvironmentList(environments);
        },
        (err) => {
          console.log('error', err);
        }
      );
    }
  }, [showModal]);

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  useEffect(() => {
    if (isSuccess) {
      setRefresh(true);
      setShowModal(false);
      setIsSuccess(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!open) {
      reset();
      setShowModal(false);
    }
  }, [open]);

  const checkIfKeyExists = (key) => {
    if (key !== '') {
      if (environmentList.find((env) => env.key.toLowerCase() === key.toLowerCase())) {
        setUniqueKeyError(true);
      } else {
        setUniqueKeyError(false);
      }
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="mx-auto flex w-full max-w-md flex-col space-y-2">
                  <div className="flex flex-col items-center justify-center space-y-2 text-center">
                    <div className="text-xl font-semibold">
                      <p>Create a Environment Variable For Connection</p>
                    </div>
                  </div>

                  <div>
                    <form
                      action=""
                      method="post"
                      onSubmit={handleSubmit(
                        async (data) => {
                          let environmentObject = { ...data };
                          delete environmentObject.value;
                          delete environmentObject.kind;

                          if (data.kind) {
                            environmentObject = {
                              ...environmentObject,
                              secretValue: data.value,
                              kind: 'secret'
                            };
                          } else {
                            environmentObject = {
                              ...environmentObject,
                              variableValue: data.value,
                              kind: 'variable'
                            };
                          }

                          if (!uniqueKeyError) {
                            setIsLoading(true);
                            const { success: successAdd } = await postEnvironment({
                              environment: environmentObject,
                              connectionName: params.connectionName
                            });
                            if (!successAdd) {
                              toast({
                                variant: 'destructive',
                                title: 'Uh oh! Something went wrong.',
                                description: 'There was a problem. Try again later.'
                              });
                              setIsLoading(false);
                              return;
                            } else {
                              toast({
                                title: 'Successfully',
                                description: 'New environment variable added.'
                              });
                              setIsSuccess(true);
                              setIsLoading(false);
                            }
                          }
                        },
                        (error) => {
                          console.log(error);
                        }
                      )}
                    >
                      <div className=" flex flex-col gap-2">
                        <div className="mx-auto flex w-full max-w-xs flex-row items-center justify-between gap-2"></div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Key*
                          </label>
                          <div className="mt-2">
                            <input
                              {...register('key', {
                                required: 'This area is required!',
                                onChange: (e) => {
                                  checkIfKeyExists(e.target.value);
                                }
                              })}
                              className="block w-full rounded-md border-0 p-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {errors.key !== undefined && (
                              <span className="label-second my-1 block !text-red-500">
                                {errors.key.message}
                              </span>
                            )}
                            {uniqueKeyError && (
                              <span className="label-second my-1 block !text-red-500">
                                {`${defaultValues.key.toLocaleLowerCase()} is  already taken`}
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Value*
                          </label>
                          <div className="mt-2">
                            <input
                              {...register('value', {
                                required: 'This area is required!'
                              })}
                              className="block w-full rounded-md border-0 p-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {errors.value !== undefined && (
                              <span className="label-second my-1 block !text-red-500">
                                {errors.value.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Description
                          </label>
                          <div className="mt-2">
                            <input
                              {...register('Description', {
                                required: false
                              })}
                              className="block w-full rounded-md border-0 p-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="flex flex-row gap-3 ">
                            <input {...register('kind')} type="checkbox" />
                            <label htmlFor="kind" className="label-main">
                              Configure as secret
                            </label>
                          </div>
                        </div>
                        <div className="mt-4 flex w-full flex-col">
                          {isLoading ? (
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                            >
                              <Spinner />
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                            >
                              Create a Variable
                            </button>
                          )}
                        </div>
                        <div></div>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
