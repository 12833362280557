import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "components/ui/chart";

export function LineChart({chartData,chartConfig,selectedDate}) { 
  return (
    <Card>
      <CardHeader>
        <CardTitle>Invocation Count Series</CardTitle>
        <CardDescription>
          Showing total visitors for the {selectedDate}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
        <AreaChart
            accessibilityLayer
            data={chartData}
            margin={{
              left: -32,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => value}
            />
             <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickCount={3}
            />
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <defs>
              <linearGradient id="fillSlack" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={chartConfig.slackTrigger.color} stopOpacity={0.8} />
                <stop offset="95%" stopColor={chartConfig.slackTrigger.color} stopOpacity={0.1} />
              </linearGradient>
              <linearGradient id="fillCron" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={chartConfig.cronTrigger.color} stopOpacity={0.8} />
                <stop offset="95%" stopColor={chartConfig.cronTrigger.color} stopOpacity={0.1} />
              </linearGradient>
              <linearGradient id="fillWebhook" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={chartConfig.webhookTrigger.color} stopOpacity={0.8} />
                <stop offset="95%" stopColor={chartConfig.webhookTrigger.color} stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <Area
              dataKey="slackTrigger"
              type="natural"
              fill="url(#fillSlack)"
              fillOpacity={0.4}
              stroke={chartConfig.slackTrigger.color}
              stackId="a"
            />
            <Area
              dataKey="cronTrigger"
              type="natural"
              fill="url(#fillCron)"
              fillOpacity={0.4}
              stroke={chartConfig.cronTrigger.color}
              stackId="a"
            />
            <Area
              dataKey="webhookTrigger"
              type="natural"
              fill="url(#fillWebhook)"
              fillOpacity={0.4}
              stroke={chartConfig.webhookTrigger.color}
              stackId="a"
            />
            <ChartLegend content={<ChartLegendContent />} />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
