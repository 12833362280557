import { useEffect, useRef, useState } from 'react';
import { FiSend } from 'react-icons/fi';
import Message from '../Message/index.js';
import useAutoResizeTextArea from 'hooks/useAutoResizeTextAre.jsx';
import BotIcon from 'assets/img/ob-bot-icon.webp';
import { addExecutionCommand } from '../io.js';

const Chat = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [conversation, setConversation] = useState([]);
  const [message, setMessage] = useState('');
  const textAreaRef = useAutoResizeTextArea();
  const bottomOfChatRef = useRef(null);
  const localConversation = localStorage.getItem('conversation');

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '24px';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [message, textAreaRef]);

  useEffect(() => {
    if (bottomOfChatRef.current) {
      setTimeout(() => {
        bottomOfChatRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [conversation]);

  const sendMessage = async (e) => {
    e.preventDefault();
  
    if (message.length < 1) {
      setErrorMessage('Please enter a message.');
      return;
    } else {
      setErrorMessage('');
    }
    setIsLoading(true);
  
    setConversation([
      ...conversation,
      { content: message, role: 'user' },
      { content: null, role: 'system' }
    ]);
  
    setMessage('');
    try {
      const { response } = await addExecutionCommand({
        commandLine: message
      });
      let newConversation;
      if (response.success) {
        let formattedResponse = '';
  
        // Handle multiple targets with outputs
        if (
          response.response?.targets &&
          Array.isArray(response.response.targets)
        ) {
          formattedResponse = response.response.targets
            .map(
              (target) =>
                `Instance ${target.instanceId}:\n${target.output}`
            )
            .join('\n\n'); // Add extra newlines between instances
        } else if (typeof response.response === 'string') {
          formattedResponse = response.response;
        } else if (typeof response.response === 'object') {
          formattedResponse = JSON.stringify(response.response, null, 2);
        }
  
        newConversation = [
          ...conversation,
          { content: message, role: 'user' },
          { content: formattedResponse, role: 'system', type: 'string' }
        ];
      } else {
        setErrorMessage(response.statusText);
  
        newConversation = [
          ...conversation,
          { content: message, role: 'user' },
          {
            content: JSON.stringify(response),
            role: 'system',
            type: 'error'
          }
        ];
  
        if (response.status === 503) {
          newConversation = [
            ...conversation,
            { content: message, role: 'user' },
            { content: 'Response timed out', role: 'system', type: 'string' }
          ];
          setErrorMessage(response.statusText);
        }
      }
  
      setIsLoading(false);
      setConversation(newConversation);
    } catch (error) {
      console.error('Error during execution:', error);
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  const handleKeypress = (e) => {
    if (e.keyCode == 13 && !e.shiftKey) {
      sendMessage(e);
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (conversation.length > 0) {
      localStorage.setItem('conversation', JSON.stringify(conversation));
    }
  }, [conversation]);

  useEffect(() => {
    if (localConversation) {
      const parseLocalConversation = JSON.parse(localConversation);
      setConversation(parseLocalConversation);
    }
  }, []);

  return (
    <div className="flex h-full max-w-full flex-1 flex-col">
      <div className="transition-width relative flex h-full w-full flex-1 flex-col items-stretch overflow-hidden bg-transparent">
        <div className="flex-1 overflow-hidden">
          <div className="relative h-full dark:bg-gray-800">
            <div className="h-full w-full overflow-y-auto">
              {conversation.length > 0 ? (
                <div className="flex flex-col items-center bg-gray-800 text-sm">
                  {conversation.map((message, index) => (
                    <Message key={index} message={message} />
                  ))}
                  <div className="h-32 w-full flex-shrink-0 bg-[#f9fafb]"></div>
                  <div ref={bottomOfChatRef}></div>
                </div>
              ) : (
                <div className="relative flex h-full w-full flex-col">
                  <h1 className="flex h-screen items-center justify-center gap-2 text-center text-xl font-semibold text-gray-600 dark:text-gray-600 sm:text-4xl">
                    <img src={BotIcon} className="h-9 w-9 object-cover" alt="ob bot icon" />
                    How can I help you today?
                  </h1>
                </div>
              )}
              <div className="flex flex-col items-center text-sm dark:bg-gray-800"></div>
            </div>
          </div>
        </div>
        <div className="md:bg-vert-light-gradient dark:md:bg-vert-dark-gradient absolute bottom-0 left-0 w-full border-t bg-[#f9fafb] pt-2 dark:border-white/20 dark:bg-gray-800 md:border-t-0 md:border-transparent md:dark:border-transparent">
          <form className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl">
            <div className="relative flex h-full flex-1 flex-col items-stretch md:flex-col">
              {errorMessage ? (
                <div className="mb-2 md:mb-0">
                  <div className="ml-1 flex h-full justify-center gap-0 md:m-auto md:mb-2 md:w-full md:gap-2">
                    <span className="text-sm text-red-500">{errorMessage}</span>
                  </div>
                </div>
              ) : null}
              <div className="relative flex w-full flex-grow flex-col rounded-md border border-black/10 bg-white py-2 shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:border-gray-900/50 dark:bg-gray-700 dark:text-white dark:shadow-[0_0_15px_rgba(0,0,0,0.10)] md:py-3 md:pl-4">
                <textarea
                  ref={textAreaRef}
                  value={message}
                  tabIndex={0}
                  data-id="root"
                  style={{
                    height: '24px',
                    maxHeight: '200px',
                    overflowY: 'hidden'
                  }}
                  // rows={1}
                  placeholder="Send a message..."
                  className="m-0 w-full resize-none border-0 bg-transparent p-0 pl-2 pr-7 outline-none focus:ring-0 focus-visible:ring-0 dark:bg-transparent md:pl-0"
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleKeypress}
                />
                <button
                  disabled={isLoading || message?.length === 0}
                  onClick={sendMessage}
                  className="absolute bottom-1.5 right-1 rounded-md bg-secondary p-1 disabled:bg-gray-500 disabled:opacity-40 md:bottom-2.5 md:right-2"
                >
                  <FiSend className="mr-1 h-4 w-4 text-white " />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Chat;