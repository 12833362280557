import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

export default function LogCharts({ dailyDataTable, dailyDataLoading }) {
  let lineOptions = {
    chart: {
      id: 'chart2',
      type: 'area',
      foreColor: '#ccc',
      toolbar: {
        autoSelected: 'pan',
        show: false
      }
    },
    colors: ['#00BAEC', '#ec3200', '#00ec32','#0d9488'],
    stroke: {
      width: 3
    },
    grid: {
      borderColor: '#555',
      clipMarkers: false,
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0
      }
    },
    markers: {
      size: 5,
      colors: ['#000524'],
      strokeColor: '#00BAEC',
      strokeWidth: 3
    },

    tooltip: {
      theme: 'dark'
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      min: 0,
      tickAmount: 4
    }
  };
  let barOptions = {
    chart: {
      id: 'chart1',
      height: 130,
      type: 'bar',
      foreColor: '#ccc',
      brush: {
        target: 'chart2',
        enabled: true
      },
      selection: {
        enabled: true,
        fill: {
          color: '#fff',
          opacity: 0.4
        }
      },
      events: {
        brushScrolled: function (chartContext, { xaxis, yaxis }) {
          // ...
        }
      }
    },
    colors: ['#00BAEC', '#ec3200', '#00ec32','#0d9488'],

    stroke: {
      width: 2
    },
    grid: {
      borderColor: '#444'
    },
    markers: {
      size: 0
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      tickAmount: 2
    }
  };
  const [series, setSeries] = useState({});

  useEffect(() => {
    setSeries(dailyDataTable);
  }, [dailyDataTable]);
  return (
    <div className="flex flex-col gap-1 divide-y-2 divide-solid divide-neutral-200 rounded-3xl bg-white p-6 shadow-lg dark:divide-gray-600 dark:bg-gray-800 mb-12">
      <div className="grid grid-cols-4">
        <div>
          <h2 className="text-xl font-bold">Event Logs Daily Data</h2>
        </div>
      </div>
      <div id="chart1">
        <Chart
          type="area"
          options={lineOptions}
          width="100%"
          height="230"
          series={[
            {
              name: 'Create',
              data: series?.create
            },
            {
              name: 'Update',
              data: series?.update
            },
            {
              name: 'Delete',
              data: series?.delete
            },
            {
              name: 'Login',
              data: series?.login
            }
          ]}
        />
      </div>
      <div id="chart2">
        <Chart
          type="bar"
          height="130"
          options={barOptions}
          series={[
            {
              name: 'Create',
              data: series?.create
            },
            {
              name: 'Update',
              data: series?.update
            },
            {
              name: 'Delete',
              data: series?.delete
            },
            {
              name: 'Login',
              data: series?.login
            }
          ]}
        />
      </div>
    </div>
  );
}
