import React, { useEffect, useState, useRef } from 'react';
import { getDebuggerWithWorkspaceId, getWorkspaceID } from './network';
import { IoCloseSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { setDebugger, setDebuggerSize } from 'redux/slices/debuggerSlice';

export default function Debugger() {
  const [workspaceId, setWorkspaceId] = useState(null);
  const [debugData, setDebugData] = useState([]);
  const debuggerState = useSelector((state) => state.debuggerState.debugger);
  const debuggerSize = useSelector((state) => state.debuggerState.debuggerSize);
  const debuggerRef = useRef(null);

  const dispatch = useDispatch();
  const fetchWorkspaceId = async () => {
    try {
      const { success, payload } = await getWorkspaceID();
      if (success) {
        setWorkspaceId(payload.workspaceId);
      }
    } catch (error) {
      console.error('Error fetching Workspace Id:', error);
    }
  };

  useEffect(() => {
    fetchWorkspaceId();
  }, []);

  useEffect(() => {
    if (workspaceId) {
      const eventSource = getDebuggerWithWorkspaceId(
        workspaceId,
        (data) => {
          console.log('SSE Data:', data);
          setDebugData((prevData) => [...prevData, data]);
        },
        (error) => {
          console.error('SSE Error:', error);
        }
      );
      return () => eventSource.close();
    }
  }, [workspaceId]);

  const handleDebugger = () => {
    dispatch(setDebugger(!debuggerState));
  };

  const handleMouseDown = (e) => {
    e.preventDefault();

    const startY = e.clientY;
    const startHeight = debuggerRef.current.offsetHeight;

    const onMouseMove = (e) => {
      const newHeight = startHeight + (startY - e.clientY);
      if (newHeight >= 0 && newHeight <= window.innerHeight - 48) {
        // 48 is the minimum header height
        dispatch(setDebuggerSize(newHeight));
      }
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 z-[51] max-h-full">
      <div
        className="fixed bottom-0 left-0 min-h-[48px] w-full"
        ref={debuggerRef}
        style={{ maxHeight: 'calc(100vh - 48px' }}
      >
        <div className="relative box-border flex h-[48px] flex-row flex-nowrap items-center justify-end border-t border-[#e3e8ee] bg-[#f7fafc] px-6 py-3">
          {/* resize*/}
          <div
            onMouseDown={handleMouseDown}
            className="absolute left-0 top-0 h-6 w-full cursor-ns-resize border-t border-[#0000]"
          >
            <div
              style={{ transform: 'translate(-50%)' }}
              className="absolute left-1/2 top-2 h-1 w-14 rounded-[2px] bg-[#697386]"
            />
          </div>
          <div className="pointer-events-none mr-auto box-border">
            <div className="-ml-2 -mt-2 box-border flex flex-row flex-nowrap items-center justify-start">
              <div className="pointer-events-auto ml-2 mt-2 box-border ">
                <span className="inline whitespace-normal text-sm font-bold leading-4 text-gray-900">
                  Debugger
                </span>
              </div>
            </div>
          </div>
          <div onClick={handleDebugger} className="box-border pl-3 drop-shadow-sm">
            <IoCloseSharp className="pointer-events-auto box-border h-4 w-4 text-[#4f566b]" />
          </div>
        </div>
        {/* terminal*/}
        <div className="overflow-hidden">
          <div className="flex items-stretch justify-center">
            <div className="flex-shrink flex-grow overflow-auto border-r border-[#ffffff1a] bg-secondary">
              <div
                style={{
                  height: `${debuggerSize}px`,
                }}
                className="box-border flex flex-col items-stretch justify-start px-5 pb-0 pt-3 opacity-60"
              >
                <div className="box-border py-8 pb-6">
                  {debugData.length === 0 ? (
                    <pre className="text-[13px] ">
                      <code className="my-4 leading-5 text-[#f5fbff]">
                        {' '}
                        Welcome to the Debugger Terminal! This is where you can inspect, test, and
                        troubleshoot your code. Enjoy debugging!
                      </code>
                    </pre>
                  ) : (
                    <>
                      {debugData.map((data, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-x-2 whitespace-nowrap py-2 text-base leading-5 text-[#f5fbff]"
                        >
                          <span>
                            {data.Timestamp !== ''
                              ? new Date(data.Timestamp).toLocaleString()
                              : '-'}
                          </span>
                          <span>-</span>
                          <span>{data.Email !== '' ? data.Email : '-'}</span>
                          <span>-</span>
                          <span>Message: {data.Message !== '' ? data.Message : '-'}</span>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
