import React, { useState, useEffect } from 'react';
import { addNewConnectionViewStates, postMethods } from 'constants/index';
import { Navigate, useParams } from 'react-router-dom';
import { fetchKeys, connectionExists, addOrUpdateConnection } from './io';
import { globalViewStates } from 'constants/index';
import { secretExists, addNewSecret } from 'features/key/KeyNew/io';
import { deleteConnection, fetchConnectionDataDetail } from '../connectionList/io';
import { fetchData } from 'features/project/ProjectList/io';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import { fetchConnectionData } from '../connectionList/io';
import sendFetchTriggersRequest from 'network/sendFetchTriggersRequest';
import { fetchPolicies } from 'features/user/io';
import { useSelector } from 'react-redux';
import { userRoles } from 'constants/index';
import { ConnectionEnvironment } from '../connectionEnvironment';
import { useToast } from 'hooks/use-toast';

const initialSqlKey = {
  name: '',
  kind: 'sql-credential',
  description: '',
  sqlCredentialInfo: {
    username: '',
    password: ''
  }
};

const initialFormModel = {
  name: '',
  kind: 'sql',
  description: '',
  secret: '',
  sqlConnectionInfo: {
    host: '',
    port: '',
    databaseName: ''
  },
  projectNames: []
};

const ConnectionNewSql = () => {
  const [viewState, setViewState] = useState(addNewConnectionViewStates.IDLE);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [showModal, setShowModal] = useState(false);
  const [secrets, setSecrets] = useState([]);
  const [newKeyControl, setNewKeyControl] = useState(false);
  const [sqlFormModel, setSqlFormModel] = useState(initialSqlKey);
  const [formErrorModelSecret, setFormErrorModelSecret] = useState({
    name: false,
    username: false,
    password: false
  });
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false,
    secret: false,
    host: false,
    port: false
  });
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [connectionList, setConnectionList] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [selectedTriggers, setSelectedTriggers] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const userState = useSelector((state) => state.userState);
  const {toast} = useToast();

  // Get Projects
  useEffect(() => {
    fetchData().then(({ success, payload }) => {
      void success;

      const { projects } = payload;
      setProjects(projects);
    });
  }, []);

  const onFormSubmitSecret = (e) => {
    e.preventDefault();

    const obj = {
      name: sqlFormModel.name.trim() === '',
      username: sqlFormModel.sqlCredentialInfo.username.trim() === '',
      password: sqlFormModel.sqlCredentialInfo.password.trim() === ''
    };

    setFormErrorModelSecret(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      onsqlFormModelSave();
    }
  };

  const onFormSubmit = (type, e) => {
    e.preventDefault();

    const obj = {
      name: type === postMethods.UPDATE ? false : formModel.name.trim() === '',
      uniqueName:
        type === postMethods.UPDATE
          ? false
          : connectionList.find(
              (connection) => connection.name.toLowerCase() === formModel.name.toLowerCase()
            )
          ? true
          : false,
      secret: formModel.secret.trim() === '',
      host: formModel.sqlConnectionInfo.host.trim() === '',
      port: formModel.sqlConnectionInfo.port.trim() === ''
    };

    setFormErrorModel(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      setFormModel({
        ...formModel
      });

      if (type === postMethods.SAVE) {
        onFormSave();
      } else if (type === postMethods.UPDATE) {
        onFormUpdate();
      }
    }
  };

  const params = useParams(); // get param
  const connectionName = params.connectionName ? params.connectionName : null;

  useEffect(() => {
    if (connectionName) {
      fetchConnectionDataDetail(connectionName).then(
        ({ success, payload }) => {
          void success;

          const { connection } = payload;

          const temporaryForm = {
            //name: connection.name, (There is no API)
            kind: 'sql',
            description: connection.description,
            secret: connection.secret,
            sqlConnectionInfo: {
              host: connection.sqlConnectionInfo.host,
              port: connection.sqlConnectionInfo.port,
              databaseName: connection.sqlConnectionInfo.databaseName
            },
            projectNames: connection.projectNames
          };
          setSelectedProjects(
            connection.projectNames
              ? connection.projectNames.map((project) => {
                  return { value: project, label: project };
                })
              : []
          );
          setFormModel(temporaryForm);
          setViewState(addNewConnectionViewStates.DONE);
        },
        (err) => {
          console.log('error', err);
        }
      );
    }
  }, [connectionName]);

  useEffect(() => {
    fetchKeys(formModel.name).then(({ success, payload }) => {
      if (!success) {
        return;
      }

      const { secrets } = payload;
      const secretsSQL = secrets.filter((item) => item.kind === 'sql-credential');
      setSecrets(secretsSQL);
    });
  }, []);

  useEffect(() => {
    fetchConnectionData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { connections } = payload;
        setConnectionList(connections);
      },
      (err) => {
        console.log('error', err);
      }
    );
    sendFetchTriggersRequest().then(({ success, payload }) => {
      void success;

      const { triggers } = payload;

      setTriggers(triggers);
    });
    fetchPolicies().then(({ success, payload }) => {
      void success;

      const { policies } = payload;

      setPolicies(policies);
    });
  }, []);

  useEffect(() => {
    if (policies) {
      const selectedPolicy = policies.filter(
        (policy) => policy.connections && policy.connections.includes(connectionName)
      );
      setSelectedPolicies(selectedPolicy);
    }
  }, [policies, connectionName]);

  useEffect(() => {
    if (triggers) {
      const selectedTrigger = triggers.filter(
        (trigger) => trigger.connections && trigger.connections.includes(connectionName)
      );
      setSelectedTriggers(selectedTrigger);
    }
  }, [triggers, connectionName]);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (!connectionName) {
        if (
          connectionList.find(
            (connection) => connection.name.toLowerCase() === formModel.name.toLowerCase()
          )
        ) {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: true
          });
        } else {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: false
          });
        }
      }
    };
    checkIfNameExists();
  }, [formModel.name, connectionList]);

  //Change Input
  const onChangeInput = (value, field) => {
    const trimmedValue = value.trim();
    if (field === 'host') {
      setFormModel((prevState) => {
        prevState.sqlConnectionInfo.host = value;
        return {
          ...prevState
        };
      });
    } else if (field === 'port') {
      setFormModel((prevState) => {
        prevState.sqlConnectionInfo.port = value;
        return {
          ...prevState
        };
      });
    } else if (field === 'databaseName') {
      setFormModel((prevState) => {
        prevState.sqlConnectionInfo.databaseName = value;
        return {
          ...prevState
        };
      });
    } else if (field === 'name' && (trimmedValue !== '' || value === '')) {
      setFormModel({
        ...formModel,
        [field]: trimmedValue
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value
      });
    }
  };

  const onFormSave = async (evt) => {
    // evt.preventDefault();

    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await connectionExists(formModel.name);

      if (!success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There was a problem. Please try again later.'
        });
        // alert('There was a problem. Try again later.');
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There’s an existing secret with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
      const { success: successAdd } = await addOrUpdateConnection({
        ...formModel
      });

      if (!successAdd) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There was a problem. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      setViewState(globalViewStates.REDIRECT_TO_PARENT);
      toast({
        title: 'Successfully',
        description: 'Added new secret'
      });
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'There was a problem. Please try again later.'
      });
    }
  };

  const onFormUpdate = async (isDelete = false) => {
    // evt.preventDefault();

    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateConnection(
        {
          ...formModel
        },
        connectionName
      );

      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There was a problem. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        if (!isDelete) {
          toast({
            title: 'Successfully',
            description: 'Connection successfully updated'
          });
        }
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'There was a problem. Please try again later.'
      });
    }
  };

  const onNewKeyClick = (evt) => {
    evt.preventDefault();
    setNewKeyControl(!newKeyControl);
  };

  const onBackClick = (evt) => {
    evt.preventDefault();
    setViewState(globalViewStates.REDIRECT_TO_PARENT);
  };

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/connections" />;
  }

  const disabled = viewState === globalViewStates.POSTING;

  // clear form
  const clearForm = () => {
    setSqlFormModel(initialSqlKey);
  };

  const onsqlFormModelSave = async (evt) => {
    //evt.preventDefault();

    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await secretExists(sqlFormModel.name);

      if (!success) {
        toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There’s an existing key with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { success: successAdd } = await addNewSecret(sqlFormModel);

      if (successAdd) {
        toast({
          title: 'Successfully',
          description: 'New SQL key save was successful'
        });
        
        setNewKeyControl(!newKeyControl);
        setViewState(globalViewStates.IDLE);
      } else {
        //alert('There was a problem. Try again later.');
        toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
        setViewState(globalViewStates.IDLE);
        return;
      }

      fetchKeys(formModel.name).then(({ success, payload }) => {
        if (!success) {
          return;
        }

        const { secrets } = payload;
        const secretSQL = secrets.filter((item) => item.kind === 'sql-credential');
        setSecrets(secretSQL);
        setSqlFormModel(initialSqlKey);
      });

      //setViewState(globalViewStates.REDIRECT_TO_PARENT);

      // Check if key exists by getting key by name.
      // add key, go back to listing.
    } catch (ex) {
      console.log(ex);
      // alert('There was a problem. Please try again later');
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'There was a problem. Please try again later.'
      });
    }
  };

  //Change Input
  const onChangeInputKey = (value, field) => {
    if (field === 'name') {
      setSqlFormModel({
        ...sqlFormModel,
        [field]: value
      });
    } else if (field === 'description') {
      setSqlFormModel({
        ...sqlFormModel,
        [field]: value
      });
    } else {
      setSqlFormModel({
        ...sqlFormModel,
        sqlCredentialInfo: {
          ...sqlFormModel.sqlCredentialInfo,
          [field]: value
        }
      });
    }
  };

  //delete connection
  const onDeleteClick = async (evt) => {
    evt.preventDefault();
    if (
      selectedTriggers.length !== 0 ||
      selectedPolicies.length !== 0 ||
      selectedProjects.length !== 0
    ) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:`This connection is being used in ${
          selectedTriggers.length !== 0 || selectedProjects.length !== 0
            ? `${selectedTriggers.map((trigger) => `'${trigger.name}'`).join(', ')} ${
                selectedProjects &&
                selectedProjects.map((project) => `'${project.value}'`).join(', ')
              } triggers`
            : ''
        }
      ${
        selectedPolicies.length !== 0
          ? `${selectedPolicies.map((policy) => `'${policy.name}'`).join(', ')} policies`
          : ''
      } 
      `
      });
    } else {
      setShowModal(!showModal);
      formModel.projectNames = [];
    }
  };

  const confirmDelete = async () => {
    setShowModal(!showModal);
    setSecrets([]);
    await onFormUpdate(true); // delete process
    deletionConfirmed();
  };

  const deletionConfirmed = (evt) => {
    if (!connectionName) {
      return;
    }
    setViewState(globalViewStates.EXECUTING);
    deleteConnection(connectionName).then(
      () => {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: `${connectionName} was deleted.`
        });
        
      },
      (err) => {
        console.log(err);
        setViewState(globalViewStates.DONE);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There was a problem. Please try again later.'
        });
      }
    );
  };

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold">
              {connectionName ? 'UPDATE SQL CONNECTION' : 'NEW SQL CONNECTION'}
            </h2>

            <div className="flex flex-col gap-6">
              <div>
                <label htmlFor="name" className="label-main">
                  Connection Name *
                </label>

                <input
                  name="name"
                  id="name"
                  disabled={disabled || connectionName}
                  readOnly={connectionName}
                  placeholder=""
                  maxLength="250"
                  required
                  className={`${connectionName ? 'input-main-disabled' : 'input-main'} ${
                    formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                  }`}
                  value={connectionName ? connectionName : formModel.name}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'name');
                  }}
                />

                <span className="label-main">
                  Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                </span>

                {formErrorModel.name && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}

                {formErrorModel.uniqueName && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="description" className="label-main">
                  Description
                </label>

                <input
                  id="description"
                  name="description"
                  disabled={disabled}
                  required
                  placeholder=""
                  maxLength={125}
                  className={`input-main`}
                  value={formModel.description}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'description');
                  }}
                />
              </div>

              <div>
                <label htmlFor="host" className="label-main">
                  Host *
                </label>

                <input
                  type="text"
                  id="host"
                  name="host"
                  disabled={disabled}
                  required
                  placeholder="e.g., 52.0.14.116"
                  className={`input-main`}
                  value={formModel.sqlConnectionInfo.host}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'host');
                  }}
                />

                {formErrorModel.host && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="port" className="label-main">
                  Port *
                </label>

                <input
                  type="text"
                  id="port"
                  name="port"
                  disabled={disabled}
                  required
                  placeholder="3306"
                  className={`input-main`}
                  value={formModel.sqlConnectionInfo.port}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'port');
                  }}
                />

                {formErrorModel.host && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="databaseName" className="label-main">
                  Database Name
                </label>

                <input
                  type="text"
                  id="databaseName"
                  name="databaseName"
                  disabled={disabled}
                  required
                  placeholder=""
                  className={`input-main`}
                  value={formModel.sqlConnectionInfo.databaseName}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'databaseName');
                  }}
                />
              </div>

              <div>
                <label htmlFor="secret" className="label-main">
                  SQL Credential *
                </label>

                <select
                  id="secret"
                  className={`input-main`}
                  value={formModel.secret}
                  required
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'secret');
                  }}
                >
                  {secrets.length === 0 ? (
                    <option>There is no authentication record.</option>
                  ) : (
                    <option value="">select an existing key</option>
                  )}

                  {secrets &&
                    secrets.map((key) => {
                      return (
                        <option value={key.name} key={key.name}>
                          {key.name}
                        </option>
                      );
                    })}
                </select>

                {formErrorModel.secret && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}

                <div className="my-4 flex">
                  <span className="label-main mr-3 mt-5 text-center">or</span>
                  <button onClick={onNewKeyClick} className="input-main !w-auto">
                    create a new key
                  </button>
                </div>

                {newKeyControl ? (
                  <div className="create-new-key relative my-5 rounded  border p-5 dark:border-gray-700">
                    <h3 className="absolute -top-2.5 left-1/2 -translate-x-1/2"> NEW KEY </h3>

                    <button
                      onClick={onNewKeyClick}
                      className="btn-basic absolute right-0 top-0 !w-auto !rounded !px-3"
                    >
                      x
                    </button>

                    <div className="flex flex-col gap-6">
                      <div>
                        <label htmlFor="sqlhName" className="label-main">
                          Name *
                        </label>

                        <input
                          value={sqlFormModel.name}
                          name="name"
                          placeholder="e.g., pk-hogwarts"
                          maxLength="250"
                          required
                          className="input-main mb-1.5"
                          onChange={(e) => {
                            onChangeInputKey(e.target.value, 'name');
                          }}
                        />

                        <span className="label-second">
                          Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                        </span>

                        {formErrorModelSecret.name && (
                          <span className="label-second my-1 block !text-red-500">
                            This area is required!
                          </span>
                        )}
                      </div>
                      <div>
                        <label htmlFor="sqlDescription" className="label-main">
                          Description
                        </label>

                        <input
                          id="description"
                          name="description"
                          required
                          placeholder=""
                          className={`input-main`}
                          value={sqlFormModel.description}
                          onChange={(e) => {
                            onChangeInputKey(e.target.value, 'description');
                          }}
                        />
                      </div>

                      <div>
                        <label htmlFor="username" className="label-main">
                          Username *
                        </label>

                        <input
                          id="username"
                          name="username"
                          required
                          placeholder=""
                          className={`input-main`}
                          value={sqlFormModel.sqlCredentialInfo.username}
                          onChange={(e) => {
                            onChangeInputKey(e.target.value, 'username');
                          }}
                        />

                        {formErrorModelSecret.username && (
                          <span className="label-second my-1 block !text-red-500">
                            This area is required!
                          </span>
                        )}
                      </div>

                      <div>
                        <label htmlFor="password" className="label-main">
                          Password *
                        </label>

                        <input
                          id="password"
                          name="password"
                          type="password"
                          required
                          placeholder=""
                          className={`input-main`}
                          value={sqlFormModel.sqlCredentialInfo.password}
                          onChange={(e) => {
                            onChangeInputKey(e.target.value, 'password');
                          }}
                        />

                        {formErrorModelSecret.password && (
                          <span className="label-second my-1 block !text-red-500">
                            This area is required!
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="flex justify-center">
                      <button type="button" className="btn-danger" onClick={clearForm}>
                        Clear
                      </button>
                      <button type="button" className="btn-success" onClick={onFormSubmitSecret}>
                        Save
                      </button>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {connectionName && (
              <div>
                <h2 className="mb-2 text-xl font-extrabold">Environment Variables</h2>
                <ConnectionEnvironment connectionName={connectionName} />
              </div>
            )}
            {userState.userRole.role !== userRoles.READ_ONLY && connectionName && (
              <>
                <h2 className="py-6 text-xl font-extrabold">Danger Zone</h2>

                <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                  <p className="label-second">
                    By clicking the button below you delete this connection. This action is{' '}
                    <strong>irreversible</strong>.
                  </p>
                  <p className="label-second mb-4">
                    You can still re-use this connection's secrets and commands in other
                    connections.
                  </p>
                  <button
                    disabled={disabled}
                    type="button"
                    onClick={onDeleteClick}
                    className={` text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                  >
                    I understand the consequences. Delete this connection
                  </button>
                </div>
              </>
            )}

            {userState.userRole.role !== userRoles.READ_ONLY && (
              <div className="mt-5 flex justify-center">
                <button type="button" className="btn-danger" onClick={onBackClick}>
                  Cancel
                </button>
                {connectionName ? (
                  <button
                    type="button"
                    className="btn-success"
                    onClick={(e) => onFormSubmit('UPDATE', e)}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-success"
                    onClick={(e) => onFormSubmit('SAVE', e)}
                  >
                    Save
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this connection?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConnectionNewSql;
