import React, { useState, useEffect } from 'react';
import { addNewConnectionViewStates, postMethods } from 'constants/index';
import { Navigate, useParams } from 'react-router-dom';
import { fetchKeys, connectionExists, addOrUpdateConnection } from './io';
import { globalViewStates } from 'constants/index';
import { secretExists, addNewSecret } from 'features/key/KeyNew/io';
import { deleteConnection, fetchConnectionDataDetail } from '../connectionList/io';
import { fetchData } from 'features/project/ProjectList/io';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import { fetchConnectionData } from '../connectionList/io';
import sendFetchTriggersRequest from 'network/sendFetchTriggersRequest';
import { fetchPolicies } from 'features/user/io';
import { useSelector } from 'react-redux';
import { userRoles } from 'constants/index';
import { ConnectionEnvironment } from '../connectionEnvironment';
import { useToast } from 'hooks/use-toast';

const InitialSshKey = {
  name: '',
  kind: 'ssh',
  description: '',
  sshInfo: {
    username: '',
    privateKey: '',
    passphrase: ''
  }
};

const InitialSshCredentialKey = {
  name: '',
  kind: 'ssh-username',
  description: '',
  sshUsernameInfo: {
    username: '',
    password: ''
  }
};

const initialFormModel = {
  name: '',
  kind: 'ssh',
  description: '',
  secret: '',
  sshConnectionInfo: {
    host: ''
  },
  projectNames: []
};

const ConnectionNew = () => {
  const [viewState, setViewState] = useState(addNewConnectionViewStates.IDLE);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [showModal, setShowModal] = useState(false);
  const [secrets, setSecrets] = useState([]);
  const [newKeyControl, setNewKeyControl] = useState(false);
  const [sshFormModel, setSshFormModel] = useState(InitialSshKey);
  const [sshCredentialFormModel, setSshCredentialFormModel] = useState(InitialSshCredentialKey);
  const [formErrorModelSecret, setFormErrorModelSecret] = useState({
    name: false,
    username: false,
    privateKey: false
  });
  const [formErrorModelSecretCredential, setFormErrorModelSecretCredential] = useState({
    name: false,
    username: false,
    password: false
  });
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false,
    secret: false,
    host: false
  });
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [connectionList, setConnectionList] = useState([]);
  const [selectedSshKeyType, setSelectedSshKeyType] = useState('ssh');
  const createSshAuthTypes = [
    {
      type: 'ssh',
      label: 'Ssh Key'
    },
    {
      type: 'ssh-username',
      label: 'Ssh Credential'
    }
  ];
  const [triggers, setTriggers] = useState([]);
  const [selectedTriggers, setSelectedTriggers] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const userState = useSelector((state) => state.userState);
  const { toast } = useToast();

  // Get Projects
  useEffect(() => {
    fetchData().then(({ success, payload }) => {
      void success;

      const { projects } = payload;
      setProjects(projects);
    });
  }, []);

  const onFormSubmitSecret = (e) => {
    e.preventDefault();

    const obj = {
      name: sshFormModel.name.trim() === '',
      username: sshFormModel.sshInfo.username.trim() === '',
      privateKey: sshFormModel.sshInfo.privateKey.trim() === ''
    };

    setFormErrorModelSecret(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      onSshFormModelSave();
    }
  };

  const onFormSubmit = (type, e) => {
    e.preventDefault();

    const obj = {
      name: type === postMethods.UPDATE ? false : formModel.name.trim() === '',
      uniqueName:
        type === postMethods.UPDATE
          ? false
          : connectionList.find(
              (connection) => connection.name.toLowerCase() === formModel.name.toLowerCase()
            )
          ? true
          : false,
      secret: formModel.secret.trim() === '',
      host: formModel.sshConnectionInfo.host.trim() === ''
    };

    setFormErrorModel(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      setFormModel({
        ...formModel
      });

      if (type === postMethods.SAVE) {
        onFormSave();
      } else if (type === postMethods.UPDATE) {
        onFormUpdate();
      }
    }
  };

  const params = useParams(); // get param
  const connectionName = params.connectionName ? params.connectionName : null;

  useEffect(() => {
    if (connectionName) {
      fetchConnectionDataDetail(connectionName).then(
        ({ success, payload }) => {
          void success;

          const { connection } = payload;

          const temporaryForm = {
            //name: connection.name, (There is no API)
            kind: 'ssh',
            description: connection.description,
            secret: connection.secret,
            sshConnectionInfo: {
              host: connection.sshConnectionInfo.host
            },
            projectNames: connection.projectNames
          };
          setSelectedProjects(
            connection.projectNames
              ? connection.projectNames.map((project) => {
                  return { value: project, label: project };
                })
              : []
          );
          setFormModel(temporaryForm);
          setViewState(addNewConnectionViewStates.DONE);
        },
        (err) => {
          console.log('error', err);
        }
      );
    }
  }, [connectionName]);

  useEffect(() => {
    fetchKeys(formModel.name).then(({ success, payload }) => {
      if (!success) {
        return;
      }

      const { secrets } = payload;
      const secretsSHH = secrets.filter(
        (item) => item.kind === 'ssh' || item.kind === 'ssh-username'
      );
      setSecrets(secretsSHH);
    });
  }, []);

  useEffect(() => {
    fetchConnectionData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { connections } = payload;
        setConnectionList(connections);
      },
      (err) => {
        console.log('error', err);
      }
    );
    sendFetchTriggersRequest().then(({ success, payload }) => {
      void success;

      const { triggers } = payload;

      setTriggers(triggers);
    });
    fetchPolicies().then(({ success, payload }) => {
      void success;

      const { policies } = payload;

      setPolicies(policies);
    });
  }, []);

  useEffect(() => {
    if (policies) {
      const selectedPolicy = policies.filter(
        (policy) => policy.connections && policy.connections.includes(connectionName)
      );
      setSelectedPolicies(selectedPolicy);
    }
  }, [policies, connectionName]);

  useEffect(() => {
    if (triggers) {
      const selectedTrigger = triggers.filter(
        (trigger) => trigger.connections && trigger.connections.includes(connectionName)
      );
      setSelectedTriggers(selectedTrigger);
    }
  }, [triggers, connectionName]);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (!connectionName) {
        if (
          connectionList.find(
            (connection) => connection.name.toLowerCase() === formModel.name.toLowerCase()
          )
        ) {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: true
          });
        } else {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: false
          });
        }
      }
    };
    checkIfNameExists();
  }, [formModel.name, connectionList]);

  //Change Input
  const onChangeInput = (value, field) => {
    const trimmedValue = value.trim();
    if (field === 'host') {
      setFormModel((prevState) => {
        prevState.sshConnectionInfo.host = value;
        return {
          ...prevState
        };
      });
    } else if (field === 'name' && (trimmedValue !== '' || value === '')) {
      setFormModel({
        ...formModel,
        [field]: trimmedValue
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value
      });
    }
  };

  const onFormSave = async (evt) => {
    // evt.preventDefault();

    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await connectionExists(formModel.name);

      if (!success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
        // alert('There was a problem. Try again later.');
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There’s an existing secret with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
      const { success: successAdd } = await addOrUpdateConnection({
        ...formModel
      });

      if (!successAdd) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      setViewState(globalViewStates.REDIRECT_TO_PARENT);
      toast({
        title: 'Successfully',
        description: 'Added new secret'
      });
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Please try again later.'
      });
    }
  };

  const onFormUpdate = async (isDelete = false) => {
    // evt.preventDefault();

    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateConnection(
        {
          ...formModel
        },
        connectionName
      );

      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        if (!isDelete) {
          toast({
            title: 'Successfully',
            description: 'Connection successfully updated'
          });
        }
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Please try again later.'
      });
    }
  };

  const onNewKeyClick = (evt) => {
    evt.preventDefault();
    setNewKeyControl(!newKeyControl);
  };

  const onBackClick = (evt) => {
    evt.preventDefault();
    setViewState(globalViewStates.REDIRECT_TO_PARENT);
  };

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/connections" />;
  }

  const disabled = viewState === globalViewStates.POSTING;

  // clear form
  const clearForm = () => {
    setSshFormModel(InitialSshKey);
  };

  const onSshFormModelSave = async (evt) => {
    //evt.preventDefault();

    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await secretExists(sshFormModel.name);

      if (!success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:"There’s an existing key with that name. Please choose a different name."
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { success: successAdd } = await addNewSecret(sshFormModel);

      if (successAdd) {
        toast({
          title: 'Successfully',
          description: 'New SSH key save was successful'
        });
        setNewKeyControl(!newKeyControl);
        setViewState(globalViewStates.IDLE);
      } else {
        //alert('There was a problem. Try again later.');
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      fetchKeys(formModel.name).then(({ success, payload }) => {
        if (!success) {
          return;
        }

        const { secrets } = payload;
        const secretsSHH = secrets.filter((item) => item.kind === 'ssh');
        setSecrets(secretsSHH);
        setSshFormModel(InitialSshKey);
        setSshCredentialFormModel(InitialSshCredentialKey);
      });

      //setViewState(globalViewStates.REDIRECT_TO_PARENT);

      // Check if key exists by getting key by name.
      // add key, go back to listing.
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'There was a problem. Please try again later.'
      });
    }
  };

  //Change Input
  const onChangeInputKey = (value, field) => {
    if (field === 'name') {
      setSshFormModel({
        ...sshFormModel,
        [field]: value
      });
    } else {
      setSshFormModel({
        ...sshFormModel,
        sshInfo: {
          ...sshFormModel.sshInfo,
          [field]: value
        }
      });
    }
  };

  //delete connection
  const onDeleteClick = async (evt) => {
    evt.preventDefault();
    if (
      selectedTriggers.length !== 0 ||
      selectedPolicies.length !== 0 ||
      selectedProjects.length !== 0
    ) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: `This connection is being used in ${
          selectedTriggers.length !== 0 || selectedProjects.length !== 0
            ? `${selectedTriggers.map((trigger) => `'${trigger.name}'`).join(', ')} ${
                selectedProjects &&
                selectedProjects.map((project) => `'${project.value}'`).join(', ')
              } triggers`
            : ''
        }
      ${
        selectedPolicies.length !== 0
          ? `${selectedPolicies.map((policy) => `'${policy.name}'`).join(', ')} policies`
          : ''
      } 
      `
      });
    } else {
      setShowModal(!showModal);
      formModel.projectNames = [];
    }
  };

  const confirmDelete = async () => {
    setShowModal(!showModal);
    setSecrets([]);
    await onFormUpdate(true); // delete process
    deletionConfirmed();
  };

  const deletionConfirmed = (evt) => {
    if (!connectionName) {
      return;
    }
    setViewState(globalViewStates.EXECUTING);
    deleteConnection(connectionName).then(
      () => {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: `${connectionName} was deleted.`
        });
      },
      (err) => {
        console.log(err);
        setViewState(globalViewStates.DONE);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There was a problem. Please try again later.'
        });
      }
    );
  };

  // SSH CREDENTIAL KEY
  //Change shh key auth types
  const onChangeKeyAuthType = (value, field) => {
    setSelectedSshKeyType(value);
  };

  //Change Input
  const onChangeInputCredentialKey = (value, field) => {
    if (field === 'name') {
      setSshCredentialFormModel({
        ...sshCredentialFormModel,
        [field]: value
      });
    } else {
      setSshCredentialFormModel({
        ...sshCredentialFormModel,
        sshUsernameInfo: {
          ...sshCredentialFormModel.sshUsernameInfo,
          [field]: value
        }
      });
    }
  };

  // clear form
  const clearFormCredential = () => {
    setSshCredentialFormModel(InitialSshCredentialKey);
  };

  const onFormSubmitSecretCredential = (e) => {
    e.preventDefault();

    const obj = {
      name: sshCredentialFormModel.name.trim() === '',
      username: sshCredentialFormModel.sshUsernameInfo.username.trim() === '',
      password: sshCredentialFormModel.sshUsernameInfo.password.trim() === ''
    };

    setFormErrorModelSecretCredential(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      onSshCredentialFormModelSave();
    }
  };

  const onSshCredentialFormModelSave = async (evt) => {
    //evt.preventDefault();

    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await secretExists(sshCredentialFormModel.name);

      if (!success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There’s an existing key with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { success: successAdd } = await addNewSecret(sshCredentialFormModel);

      if (successAdd) {
        toast({
          title: 'Successfully',
          description: 'New SSH Credential key save was successful'
        });
        setNewKeyControl(!newKeyControl);
        setViewState(globalViewStates.IDLE);
      } else {
        //alert('There was a problem. Try again later.');
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      fetchKeys(formModel.name).then(({ success, payload }) => {
        if (!success) {
          return;
        }

        const { secrets } = payload;
        const secretsCREDENTIALS = secrets.filter((item) => item.kind === 'ssh-username');
        setSecrets(secretsCREDENTIALS);
        setSshCredentialFormModel(InitialSshCredentialKey);
      });
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'There was a problem. Please try again later.'
      });
    }
  };

  const btnExtraClassName = disabled ? 'opacity-50 cursor-not-allowed' : '';
  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold">
              {connectionName ? 'UPDATE SSH CONNECTION' : 'NEW SSH CONNECTION'}
            </h2>
            <div className="flex flex-col gap-6">
              <div>
                <label htmlFor="name" className="label-main">
                  Connection Name *
                </label>

                <input
                  name="name"
                  id="name"
                  disabled={disabled || connectionName}
                  readOnly={connectionName}
                  placeholder=""
                  maxLength="250"
                  required
                  className={`${connectionName ? 'input-main-disabled' : 'input-main'} ${
                    formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                  }`}
                  value={connectionName ? connectionName : formModel.name}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'name');
                  }}
                />

                <span className="label-main">
                  Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                </span>

                {formErrorModel.name && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}

                {formErrorModel.uniqueName && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="description" className="label-main">
                  Description
                </label>

                <input
                  id="description"
                  name="description"
                  disabled={disabled}
                  required
                  placeholder=""
                  className={`input-main`}
                  value={formModel.description}
                  maxLength={125}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'description');
                  }}
                />
              </div>

              <div>
                <label htmlFor="host" className="label-main">
                  Host *
                </label>

                <input
                  type="text"
                  id="host"
                  name="host"
                  disabled={disabled}
                  required
                  placeholder="e.g., 52.0.14.116"
                  className={`input-main`}
                  value={formModel.sshConnectionInfo.host}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'host');
                  }}
                />

                {formErrorModel.host && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="secret" className="label-main">
                  SSH Private Key *
                </label>

                <select
                  id="secret"
                  className={`input-main`}
                  value={formModel.secret}
                  required
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'secret');
                  }}
                >
                  {secrets.length === 0 ? (
                    <option>There is no authentication record.</option>
                  ) : (
                    <option value="">select an existing key</option>
                  )}

                  {secrets &&
                    secrets.map((key) => {
                      return (
                        <option value={key.name} key={key.name}>
                          {key.name}
                        </option>
                      );
                    })}
                </select>

                {formErrorModel.secret && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}

                <div className="my-4 flex">
                  <span className="label-main mr-3 mt-5 text-center">or</span>
                  <button onClick={onNewKeyClick} className="input-main !w-auto">
                    create a new key
                  </button>
                </div>

                {newKeyControl && (
                  <>
                    {createSshAuthTypes.map((item, index) => {
                      return (
                        <div className="ml-0.5 mt-2 flex items-center" key={index}>
                          <input
                            type="radio"
                            name="selectedSshKeyType"
                            checked={selectedSshKeyType === item.type}
                            value={item.type}
                            onChange={(e) => {
                              onChangeKeyAuthType(e.target.value, 'selectedSshKeyType');
                            }}
                          />

                          <button
                            type="button"
                            htmlFor="selectedSshKeyType"
                            className="label-main ml-2"
                            onClick={(e) => {
                              onChangeKeyAuthType(item.type, 'selectedSshKeyType');
                            }}
                          >
                            {item.label}
                          </button>
                        </div>
                      );
                    })}
                    {selectedSshKeyType === 'ssh' ? (
                      <div className="create-new-key relative my-5 rounded  border p-5 dark:border-gray-700">
                        <h3 className="absolute -top-2.5 left-1/2 -translate-x-1/2 bg-white">
                          {' '}
                          NEW SSH KEY{' '}
                        </h3>

                        <div className="flex flex-col gap-6">
                          <div>
                            <label htmlFor="sshName" className="label-main">
                              Name *
                            </label>

                            <input
                              value={sshFormModel.name}
                              name="name"
                              placeholder="e.g., pk-hogwarts"
                              maxLength="250"
                              required
                              className="input-main mb-1.5"
                              onChange={(e) => {
                                onChangeInputKey(e.target.value, 'name');
                              }}
                            />

                            <span className="label-second">
                              Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                            </span>

                            {formErrorModelSecret.name && (
                              <span className="label-second my-1 block !text-red-500">
                                This area is required!
                              </span>
                            )}
                          </div>

                          <div>
                            <label htmlFor="description" className="label-main">
                              Username *
                            </label>

                            <input
                              id="username"
                              name="username"
                              required
                              placeholder=""
                              className={`input-main`}
                              value={sshFormModel.sshInfo.username}
                              onChange={(e) => {
                                onChangeInputKey(e.target.value, 'username');
                              }}
                            />

                            {formErrorModelSecret.username && (
                              <span className="label-second my-1 block !text-red-500">
                                This area is required!
                              </span>
                            )}
                          </div>

                          <div>
                            <label htmlFor="description" className="label-main">
                              Passphrase (Optional)
                            </label>

                            <input
                              id="passphrase"
                              name="passphrase"
                              type="password"
                              required
                              placeholder=""
                              className={`input-main`}
                              value={sshFormModel.sshInfo.passphrase}
                              onChange={(e) => {
                                onChangeInputKey(e.target.value, 'passphrase');
                              }}
                            />
                          </div>

                          <div>
                            <label htmlFor="sshKey" className="label-main">
                              SSH Private Key *
                            </label>

                            <textarea
                              value={sshFormModel.sshInfo.privateKey}
                              name="privateKey"
                              rows={15}
                              required
                              placeholder="-----BEGIN RSA PRIVATE KEY-----
  MIIEpAIBAAKCAQEA14b1CAPmqsj3oCTPTdBypCcLDHvYPmA44TaTEQEGGJR0J3om
  YGpcX+YuibyvAKHwHCRDtoNKDLQIVuV1ZTI0e2s7KP1yWXvRVOeKfGs/Z7APKaCz
  ZxPDh0p9a+gTPJSDZjuiOgBDY3RYB92bICVl8ShJOEnXu+AERI/coE8TpoTAlLwa
  1g6YU/0CbFZu1wcwjjLsc8XBGlRFyqRipTtKCmvUYveliZZcjZuE9vzpkLC4eNhT
  Ydn1xRIYXM/YLInIRJWaQDzSr1jMBehe70aQ0yVV6N4RPeG50lZKDayDGxqZ4ouf
  ybxp4vMtRQ+aYLiRW4m3IkJVcedkOelKVPMlnwIDAQABAoIBAH9kTNb0/1W5PeyR
  dVsIN4rhbP1vAk00MMqlqF3nngPPU6y13ZMlqv4gHyfYuBQGo9budHuftJg3napH
  LJGpEC5XHnvG+tz7e6rUxR1vguW1wfCYBRKLMwp3tBSg8yXenqBB5UylNS/G5/WW
  PgfNMylM56J2uyZYbg1cf0reEr5cKR2tVPhUMA8rwsDKbl7pCsWsXJ2lb47jSxtl
  24jHJg7whd1pCrTqg6D98lh4LjpraKJlr0JctOSw4oEBL+e/pzoEAob6PpKkbybX
  RVcZ1GshEVieMm06S0leI17Sc5jUFowWi0RbFdhJGnku8nv5mCt6s7JGSTOagbev
  bWWzpBkCgYEA+HnVFZmOBbSYIfMoFDGmS1mrPGlSmz7NcDSol0FehrV/NW3dWF2P
  DfN3Tfxrx7iyNKtArw+nnpYXj5Cmb12hhd6MsL8TonSxjDn19dvJDP0ytdRpaEDa
  wGMYZKQcLyVvvTczp+9MgYRnWgkpGMgklwqxSjuC21zIV5RCn2mLnmsCgYEA3g21
  ZEVvpTeRApII+XGGLvx7JHqZO3c6WHqtQezytHLMWiKcU4Q/Bxl+Sec7q7/cmvzS
  iLQBUodD7B6zD9zSSSRAJM+RUUsELoGuuShTiIWEXPbfKJRDCYJRdaPkoBikgIDv
  aPsq5FytILf+xoSxKSTzlEoHtNxZH8+aFz98ep0CgYBELjIRJjx5Rk/EaRNjTyhh
  Xq6E+iWHodj/ZAOq1W4Zqq3IPGhTOgDqBBeFl7D/cae8mg06vCHzex3l7/wrbNbI
  hAO48AV0O28ImlLqbq+hV8zVZf6yc7FSvx5PZ1SwfyZFFmOfPiORGoK9iuvy9+BK
  bc2uH9xNUw1KMs2BLpX4FwKBgQDU6KQB7qnr6ml543gDZJxaNPAFvB9saxdges/h
  JEZaSrAxxHnLgkPElOcICVSniyEE4mEjfiS+cr/niE/8Z6ihRMbUBMm6/oiV5vYW
  xtnBTvPpBzRVfkMAvRlKCMlP0BD6vaXQLPOlXk8JziHVSqlrX+jFSG43NgemfrGq
  lM7P6QKBgQDDXU0OMjO4uZpG7/5ouErlrXLmn/5LExn3d89dorXMd3mJPQ41djm5
  fLpJGJdfN6YDxiRmMy6vzvXhhSZPQzKSCt3N9kJAZ2eBRVn5Dh9fN6/evF8lDfoq
  EZZd0b3jCs1L1No7cUUz5JU1epe5A9lwB7XdY26IU+Q1hHcjQfAn5A==
  -----END RSA PRIVATE KEY-----"
                              className="input-main !h-auto !p-3"
                              onChange={(e) => {
                                onChangeInputKey(e.target.value, 'privateKey');
                              }}
                            />

                            <span className="label-second">
                              Your private key typically begins with ‘ssh-rsa’, ‘ssh-ed25520’,
                              ‘ecdsa-sha2-nistp256’, ‘ecdsa-sha2-nistp384’, or
                              ‘ecdsa-sha2-nistp521’.
                            </span>

                            {formErrorModelSecret.privateKey && (
                              <span className="my-1 block text-sm !text-red-500">
                                This area is required!
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="flex justify-center">
                          <button type="button" className="btn-danger" onClick={clearForm}>
                            Clear
                          </button>
                          <button
                            type="button"
                            className="btn-success"
                            onClick={onFormSubmitSecret}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="create-new-key relative my-5 rounded  border p-5 dark:border-gray-700">
                        <h3 className="absolute -top-2.5 left-1/2 -translate-x-1/2 bg-white">
                          {' '}
                          NEW SSH CREDENTIAL KEY{' '}
                        </h3>

                        <div className="flex flex-col gap-6">
                          <div>
                            <label htmlFor="sshName" className="label-main">
                              Name *
                            </label>

                            <input
                              value={sshCredentialFormModel.name}
                              name="name"
                              placeholder="e.g., pk-hogwarts"
                              maxLength="250"
                              required
                              className="input-main mb-1.5"
                              onChange={(e) => {
                                onChangeInputCredentialKey(e.target.value, 'name');
                              }}
                            />

                            <span className="label-second">
                              Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                            </span>

                            {formErrorModelSecretCredential.name && (
                              <span className="label-second my-1 block !text-red-500">
                                This area is required!
                              </span>
                            )}
                          </div>

                          <div>
                            <label htmlFor="description" className="label-main">
                              Username *
                            </label>

                            <input
                              id="username"
                              name="username"
                              required
                              placeholder=""
                              className={`input-main`}
                              value={sshCredentialFormModel.sshUsernameInfo.username}
                              onChange={(e) => {
                                onChangeInputCredentialKey(e.target.value, 'username');
                              }}
                            />

                            {formErrorModelSecretCredential.username && (
                              <span className="label-second my-1 block !text-red-500">
                                This area is required!
                              </span>
                            )}
                          </div>

                          <div>
                            <label htmlFor="password" className="label-main">
                              Password *
                            </label>

                            <input
                              id="password"
                              name="password"
                              type="password"
                              required
                              placeholder=""
                              className={`input-main`}
                              value={sshCredentialFormModel.sshUsernameInfo.password}
                              onChange={(e) => {
                                onChangeInputCredentialKey(e.target.value, 'password');
                              }}
                            />
                          </div>
                          {formErrorModelSecretCredential.password && (
                            <span className="label-second my-1 block !text-red-500">
                              This area is required!
                            </span>
                          )}
                        </div>

                        <div className="flex justify-center">
                          <button
                            type="button"
                            className="btn-danger"
                            onClick={clearFormCredential}
                          >
                            Clear
                          </button>
                          <button
                            type="button"
                            className="btn-success"
                            onClick={onFormSubmitSecretCredential}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {connectionName && (
              <div>
                <h2 className="mb-2 text-xl font-extrabold">Environment Variables</h2>
                <ConnectionEnvironment connectionName={connectionName} />
              </div>
            )}
            {userState.userRole.role !== userRoles.READ_ONLY && connectionName && (
              <>
                <h2 className="py-6 text-xl font-extrabold">Danger Zone</h2>

                <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                  <p className="label-second">
                    By clicking the button below you delete this connection. This action is{' '}
                    <strong>irreversible</strong>.
                  </p>
                  <p className="label-second mb-4">
                    You can still re-use this connection's secrets and commands in other
                    connections.
                  </p>
                  <button
                    disabled={disabled}
                    type="button"
                    onClick={onDeleteClick}
                    className={`${btnExtraClassName} text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                  >
                    I understand the consequences. Delete this connection
                  </button>
                </div>
              </>
            )}
            {userState.userRole.role !== userRoles.READ_ONLY && (
              <div className="mt-5 flex justify-center">
                <button type="button" className="btn-danger" onClick={onBackClick}>
                  Cancel
                </button>
                {connectionName ? (
                  <button
                    type="button"
                    className="btn-success"
                    onClick={(e) => onFormSubmit('UPDATE', e)}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-success"
                    onClick={(e) => onFormSubmit('SAVE', e)}
                  >
                    Save
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this connection?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConnectionNew;
