import React, { useState, useEffect } from 'react';
import { authTypes, createOrSelectExistingChoosing, postMethods } from 'constants/index';
import { BiX, BiPlus } from 'react-icons/bi';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { Navigate, useParams } from 'react-router-dom';
import { globalViewStates } from 'constants/index';
import { addNewSecret, secretExists } from 'features/key/KeyNew/io';
import { addOrUpdateConnection, connectionExists, fetchKeys } from './io';
import { deleteConnection, fetchConnectionDataDetail } from '../connectionList/io';
import BasicInformation from 'components/information/BasicInformation';
import { fetchData } from 'features/project/ProjectList/io';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import { fetchConnectionData } from '../connectionList/io';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setNextStep, setOnboardActiveStatus } from 'redux/slices/onboardSlice';
import sendFetchTriggersRequest from 'network/sendFetchTriggersRequest';
import { fetchPolicies } from 'features/user/io';
import KeyNewCms from 'features/key/KeyNew/KeyNewCms';
import { userRoles } from 'constants/index';
import { ConnectionEnvironment } from '../connectionEnvironment';
import { useToast } from 'hooks/use-toast';

const InitialCredentialFormModel = {
  name: '',
  kind: 'credential',
  description: '',
  credentialInfo: {
    username: '',
    password: ''
  }
};

const InitialRestTokenAuthFormModel = {
  name: '',
  kind: 'rest-token-auth',
  description: '',
  credentialInfo: {
    username: '',
    password: ''
  }
};

const InitialApiKeyFormModel = {
  name: '',
  kind: 'rest',
  description: '',
  restInfo: {
    apiKeyName: '',
    apiKeyValue: ''
  }
};

const initialFormModel = {
  name: '',
  kind: 'rest',
  description: '',
  secret: '',
  restConnectionInfo: {
    apiKeyLocation: 'header',
    tokenAuthEndpointUrl: '',
    baseUrl: '',
    headers: [
      /*{ key: '', value: '' }*/
    ]
  },
  projectNames: []
};

const initialFormModelUrl = {
  http: 'https://',
  url: ''
};

const initialBearerFormModel = {
  name: '',
  kind: 'bearer-token',
  description: '',
  bearerInfo: {
    bearerToken: ''
  }
};

const ConnectionNewRest = () => {
  const authenticationTypes = [
    'NONE',
    'BASIC AUTH',
    'API KEY',
    'REST TOKEN AUTH',
    'BEARER TOKEN',
    'CMS CREDENTIAL'
  ];
  const createOrSelectExistingTypes = [
    {
      typeName: 'SELECT',
      labelName: 'Select existing authorization'
    },
    {
      typeName: 'CREATE',
      labelName: 'Create New'
    }
  ];

  const [viewState, setViewState] = useState(globalViewStates.IDLE);
  const [secrets, setSecrets] = useState([]);
  const [formModelCredential, setFormModelCredential] = useState(InitialCredentialFormModel);
  const [formModelRestTokenAuth, setFormModelRestTokenAuth] = useState(
    InitialRestTokenAuthFormModel
  );
  const [formModelApiKey, setFormModelApiKey] = useState(InitialApiKeyFormModel);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [formModelBearerToken, setFormModelBearerToken] = useState(initialBearerFormModel);
  const [showModal, setShowModal] = useState(false);
  const [formModelUrl, setFormModelUrl] = useState(initialFormModelUrl);
  const [selectedAuthenticationType, setSelectedAuthenticationType] = useState('NONE');
  const [createOrSelectExisting, setCreateOrSelectExisting] = useState('');
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false,
    baseUrl: false,
    headers: false
  });
  const [formErrorModelCredential, setFormErrorModelCredential] = useState({
    name: false,
    userName: false,
    password: false
  });
  const [formErrorModelRestToken, setFormErrorModelRestToken] = useState({
    name: false,
    userName: false,
    password: false
  });
  const [formErrorModelApiKey, setFormErrorModelApiKey] = useState({
    name: false,
    apiKeyName: false,
    apiKeyValue: false
  });
  const [formErrorModelBearerToken, setFormErrorModelBearerToken] = useState({
    name: false,
    bearerToken: false
  });
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [connectionList, setConnectionList] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [selectedTriggers, setSelectedTriggers] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);

  const [isInnerFormSuccess, setIsInnerFormSuccess] = useState(false);

  const onboardState = useSelector((state) => state.onboardState);
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userState);
  const { toast } = useToast();

  useEffect(() => {
    setTimeout(() => {
      if (onboardState.tourActive && !onboardState.run && onboardState.stepIndex === 9) {
        dispatch(setNextStep(onboardState.stepIndex));
        dispatch(setOnboardActiveStatus(true));
      }
    }, 300);
  }, [onboardState.run]);

  useEffect(() => {
    if (onboardState.tourActive && onboardState.run) {
      setFormModel({
        ...formModel,
        name: onboardState.restConnectionGuideObject.name
      });
      setFormModelUrl({
        ...formModelUrl,
        url: onboardState.restConnectionGuideObject.baseUrl
      });
    }
  }, [onboardState.run]);

  // Get Projects
  useEffect(() => {
    fetchData().then(({ success, payload }) => {
      void success;

      const { projects } = payload;
      setProjects(projects);
    });
  }, []);

  const onFormSubmitSecretBasicAuth = (type, e) => {
    // e.preventDefault();

    const obj = {
      name: formModelCredential.name.trim() === '',
      userName: formModelCredential.credentialInfo.username.trim() === '',
      password: formModelCredential.credentialInfo.password.trim() === ''
    };

    setFormErrorModelCredential(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      onFormSaveSecretCredential();
    }
  };

  const onFormSubmitSecretApiKey = (e) => {
    e.preventDefault();

    const obj = {
      name: formModelApiKey.name.trim() === '',
      apiKeyName: formModelApiKey.restInfo.apiKeyName.trim() === '',
      apiKeyValue: formModelApiKey.restInfo.apiKeyValue.trim() === ''
    };

    setFormErrorModelApiKey(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      onFormSaveSecretApiKey();
    }
  };

  const onFormSubmit = (type, e) => {
    e.preventDefault();

    const headerControl = formModel.restConnectionInfo.headers.map((header, index) => {
      return header.key.trim() === '' ? true : false;
    });

    const obj = {
      name: type === postMethods.UPDATE ? false : formModel.name.trim() === '',
      baseUrl: formModel.restConnectionInfo.baseUrl.trim() === '',
      headers: headerControl.includes(true)
    };

    setFormErrorModel(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      setFormModel({
        ...formModel
      });

      if (type === postMethods.SAVE) {
        onFormSave();
      } else if (type === postMethods.UPDATE) {
        onFormUpdate();
      }
    }
  };

  const findAuthType = (selectedSecret) => {
    fetchKeys().then(({ success, payload }) => {
      if (!success) {
        return;
      }

      const { secrets } = payload;

      const authorizationSecret = secrets?.filter((item) => item.name === selectedSecret);
      let authType = authorizationSecret[0]?.kind;

      if (authType === 'rest') {
        authType = 'API KEY';
      } else if (authType === 'credential') {
        authType = 'BASIC AUTH';
      } else if (authType === 'rest-token-auth') {
        authType = 'REST TOKEN AUTH';
      } else if (authType === 'bearer-token') {
        authType = 'BEARER TOKEN';
      } else if (authType === 'cms-credential') {
        authType = 'CMS CREDENTIAL';
      } else {
        authType = 'NONE';
      }

      setSelectedAuthenticationType(authType);

      setTimeout(() => {
        setCreateOrSelectExisting('SELECT');
      }, 300);
    });
  };

  const params = useParams(); // get param
  const connectionName = params.connectionName ? params.connectionName : null;

  function removeHttp(url) {
    return url.replace(/^https?:\/\//, '');
  }

  function getProtocol(url) {
    const protocol = url.split(':')[0];
    return `${protocol}://`;
  }

  useEffect(() => {
    if (connectionName) {
      fetchConnectionDataDetail(connectionName).then(
        ({ success, payload }) => {
          void success;

          const { connection } = payload;

          const temporaryForm = {
            name: connection.name,
            kind: 'rest',
            description: connection.description,
            secret: connection.secret,
            restConnectionInfo: {
              apiKeyLocation: connection.restConnectionInfo.apiKeyLocation,
              tokenAuthEndpointUrl: connection.restConnectionInfo.TokenAuthEndpointUrl,
              baseUrl: connection.restConnectionInfo.baseUrl,
              headers: connection.restConnectionInfo.headers.map((header, index) => {
                return {
                  key: header.key,
                  value: header.value
                };
              })
            },
            projectNames: connection.projectNames
          };
          setSelectedProjects(
            connection.projectNames
              ? connection.projectNames.map((project) => {
                  return { value: project, label: project };
                })
              : []
          );
          setFormModel(temporaryForm);
          setFormModelUrl((prevState) => {
            prevState.url = removeHttp(connection.restConnectionInfo.baseUrl);
            prevState.http = getProtocol(connection.restConnectionInfo.baseUrl);
            return {
              ...prevState
            };
          });
          setViewState(globalViewStates.DONE);
          findAuthType(connection.secret);
        },
        (err) => {
          console.log('error', err);
        }
      );
    }
  }, [connectionName]);

  // Get Secrets
  useEffect(() => {
    if (selectedAuthenticationType !== authTypes.NONE) {
      fetchKeys(formModel.name).then(({ success, payload }) => {
        if (!success) {
          return;
        }

        const { secrets } = payload;
        let secretsREST = [];

        if (selectedAuthenticationType === authTypes.API_KEY) {
          secretsREST = secrets.filter((item) => item.kind === 'rest');
        } else if (selectedAuthenticationType === authTypes.BASIC_AUTH) {
          secretsREST = secrets.filter((item) => item.kind === 'credential');
        } else if (selectedAuthenticationType === authTypes.REST_TOKEN_AUTH) {
          secretsREST = secrets.filter((item) => item.kind === 'rest-token-auth');
        } else if (selectedAuthenticationType === authTypes.BEARER_TOKEN) {
          secretsREST = secrets.filter((item) => item.kind === 'bearer-token');
        } else if (selectedAuthenticationType === authTypes.CMS_CREDENTIAL) {
          secretsREST = secrets.filter((item) => item.kind === 'cms-credential');
        } else {
          secretsREST = secrets.filter((item) => item.kind !== 'ssh');
        }

        setSecrets(secretsREST);
      });
    }
  }, [selectedAuthenticationType, formModel.name]);

  useEffect(() => {
    fetchConnectionData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { connections } = payload;
        setConnectionList(connections);
      },
      (err) => {
        console.log('error', err);
      }
    );
    sendFetchTriggersRequest().then(({ success, payload }) => {
      void success;

      const { triggers } = payload;

      setTriggers(triggers);
    });
    fetchPolicies().then(({ success, payload }) => {
      void success;

      const { policies } = payload;

      setPolicies(policies);
    });
  }, []);

  useEffect(() => {
    if (policies) {
      const selectedPolicy = policies.filter(
        (policy) => policy.connections && policy.connections.includes(connectionName)
      );
      setSelectedPolicies(selectedPolicy);
    }
  }, [policies, connectionName]);

  useEffect(() => {
    if (triggers) {
      const selectedTrigger = triggers.filter(
        (trigger) => trigger.connections && trigger.connections.includes(connectionName)
      );
      setSelectedTriggers(selectedTrigger);
    }
  }, [triggers, connectionName]);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (!connectionName) {
        if (
          connectionList.find(
            (connection) => connection.name.toLowerCase() === formModel.name.toLowerCase()
          )
        ) {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: true
          });
        } else {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: false
          });
        }
        if (!onboardState.tourActive) {
          formModelUrl.url = '';
        }
      }
    };
    checkIfNameExists();
  }, [formModel.name, connectionList]);

  useEffect(() => {
    if (isInnerFormSuccess) {
      fetchKeys(formModel.name).then(({ success, payload }) => {
        if (!success) {
          return;
        }

        const { secrets } = payload;
        let secretsREST = [];

        if (selectedAuthenticationType === authTypes.API_KEY) {
          secretsREST = secrets.filter((item) => item.kind === 'rest');
        } else if (selectedAuthenticationType === authTypes.BASIC_AUTH) {
          secretsREST = secrets.filter((item) => item.kind === 'credential');
        } else if (selectedAuthenticationType === authTypes.REST_TOKEN_AUTH) {
          secretsREST = secrets.filter((item) => item.kind === 'rest-token-auth');
        } else if (selectedAuthenticationType === authTypes.BEARER_TOKEN) {
          secretsREST = secrets.filter((item) => item.kind === 'bearer-token');
        } else if (selectedAuthenticationType === authTypes.CMS_CREDENTIAL) {
          secretsREST = secrets.filter((item) => item.kind === 'cms-credential');
        } else {
          secretsREST = secrets.filter((item) => item.kind !== 'ssh');
        }

        setSecrets(secretsREST);
        setFormModelApiKey(InitialApiKeyFormModel);
        setCreateOrSelectExisting('SELECT');
        setIsInnerFormSuccess(false);
      });
    }
  }, [isInnerFormSuccess]);

  /********************   REST API   *********************/

  //Change Input
  const onChangeInput = (value, field) => {
    const trimmedValue = value.trim();
    if (field === 'name' && (trimmedValue !== '' || value === '')) {
      setFormModel({
        ...formModel,
        [field]: trimmedValue
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value
      });
    }
  };

  // Change Base Url
  const onChangeInputUrl = (value, field) => {
    setFormModelUrl({
      ...formModelUrl,
      [field]: value
    });

    const newUrl =
      field === 'http' ? value + '' + formModelUrl.url : formModelUrl.http + '' + value;

    setFormModel((prevState) => {
      prevState.restConnectionInfo.baseUrl = newUrl;
      return {
        ...prevState
      };
    });
  };

  //Change Headers
  const onChangeHeaders = (value, field, index) => {
    setFormModel((prevState) => {
      if (field === 'key') {
        prevState.restConnectionInfo.headers[index].key = value;
      } else if (field === 'value') {
        prevState.restConnectionInfo.headers[index].value = value;
      }

      return {
        ...prevState
      };
    });
  };

  // Change API KEY Location
  const onChangeApiKeyLocation = (value, field) => {
    setFormModel((prevState) => {
      prevState.restConnectionInfo.apiKeyLocation = value;
      return {
        ...prevState
      };
    });
  };

  const onChangeTokenAuthEndpointUrl = (value, field) => {
    setFormModel((prevState) => {
      prevState.restConnectionInfo.tokenAuthEndpointUrl = value;
      return {
        ...prevState
      };
    });
  };

  const addHeader = (e) => {
    e.preventDefault();

    const newArray = [...formModel.restConnectionInfo.headers, { key: '', value: '' }];
    setFormModel({
      ...formModel,
      restConnectionInfo: {
        ...formModel.restConnectionInfo,
        headers: newArray
      }
    });
  };

  // Delete Header
  const deleteHeader = (item, e) => {
    e.preventDefault();

    const newArray = [
      ...formModel.restConnectionInfo.headers.filter((header) => header.key !== item.key)
    ];

    setFormModel({
      ...formModel,
      restConnectionInfo: {
        ...formModel.restConnectionInfo,
        headers: newArray
      }
    });
  };

  //Change Input Create OR Select Existing
  const onChangeInputCreateOrSelectExisting = (value, field) => {
    setCreateOrSelectExisting(value);
  };

  // clear form
  const clearForm = () => {
    setFormModel(initialFormModel);
    setFormModelUrl(initialFormModelUrl);
  };

  const onFormSave = async (evt) => {
    // evt.preventDefault();

    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await connectionExists(formModel.name);

      if (!success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        // alert('There was a problem. Try again later.');
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:
            'There’s an existing connection with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
      const { success: successAdd } = await addOrUpdateConnection({
        ...formModel
      });

      if (!successAdd) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: 'New connection added.'
        });
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Please try again later.'
      });
    }
  };

  const onFormUpdate = async (isDelete = false) => {
    // evt.preventDefault();

    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateConnection(
        {
          ...formModel
        },
        connectionName
      );

      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        if (!isDelete) {
          toast({
            title: 'Successfully',
            description: 'Connection successfully updated'
          });
        }
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Please try again later.'
      });
    }
  };

  //Change Authentication Type
  const onChangeAuthenticationType = (value, field) => {
    setSelectedAuthenticationType(value);
  };

  /********************   API KEY   *********************/

  //Change Api Key
  const onChangeApiKey = (value, field) => {
    setFormModelApiKey((prevState) => {
      if (field === 'name') {
        prevState.name = value;
      } else if (field === 'apiKeyName') {
        prevState.restInfo.apiKeyName = value;
      } else if (field === 'apiKeyValue') {
        prevState.restInfo.apiKeyValue = value;
      }
      return {
        ...prevState
      };
    });
  };

  // clear form
  const clearFormApiKey = () => {
    setFormModelApiKey(InitialApiKeyFormModel);
  };

  /********************   CREDENTIAL   *********************/
  // clear form
  const clearFormCredential = () => {
    setFormModelCredential(InitialCredentialFormModel);
  };

  //Change Input Credential
  const onChangeInputCredential = (value, field) => {
    if (field === 'name') {
      setFormModelCredential({
        ...formModelCredential,
        [field]: value
      });
    } else {
      setFormModelCredential({
        ...formModelCredential,
        credentialInfo: {
          ...formModelCredential.credentialInfo,
          [field]: value
        }
      });
    }
  };
  /********************   SAVE SECRET   *********************/
  const onFormSaveSecretCredential = async (evt) => {
    // evt.preventDefault();

    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await secretExists(formModelCredential.name);

      if (!success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There’s an existing secret with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
      const { success: successAdd } = await addNewSecret(formModelCredential);

      if (!successAdd) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      setViewState(globalViewStates.IDLE);
      toast({
        title: 'Successfully',
        description: 'New secret added.'
      });

      fetchKeys(formModel.name).then(({ success, payload }) => {
        if (!success) {
          return;
        }

        const { secrets } = payload;
        const secretsCredential = secrets.filter((item) => item.kind === 'credential');
        setSecrets(secretsCredential);
        setFormModelCredential(InitialCredentialFormModel);
      });

      setCreateOrSelectExisting('SELECT');

      // Check if credential exists by getting credential by name.
      // add credential, go back to listing.
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
    }
  };

  const onFormSaveSecretApiKey = async (evt) => {
    // evt.preventDefault();

    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await secretExists(formModelApiKey.name);

      if (!success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There’s an existing secret with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
      const { success: successAdd } = await addNewSecret(formModelApiKey);

      if (!successAdd) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      setViewState(globalViewStates.IDLE);
      toast({
        title: 'Successfully',
        description: 'New secret added.'
      });

      fetchKeys(formModel.name).then(({ success, payload }) => {
        if (!success) {
          return;
        }

        const { secrets } = payload;
        const secretsREST = secrets.filter((item) => item.kind === 'rest');
        setSecrets(secretsREST);
        setFormModelApiKey(InitialApiKeyFormModel);
      });

      setCreateOrSelectExisting('SELECT');

      // Check if credential exists by getting credential by name.
      // add credential, go back to listing.
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
    }
  };

  //delete connection
  const onDeleteClick = async (evt) => {
    evt.preventDefault();
    if (
      selectedTriggers.length !== 0 ||
      selectedPolicies.length !== 0 ||
      selectedProjects.length !== 0
    ) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: `This connection is being used in ${
          selectedTriggers.length !== 0 || selectedProjects.length !== 0
            ? `${selectedTriggers.map((trigger) => `'${trigger.name}'`).join(', ')} ${
                selectedProjects &&
                selectedProjects.map((project) => `'${project.value}'`).join(', ')
              } triggers`
            : ''
        }
      ${
        selectedPolicies.length !== 0
          ? `${selectedPolicies.map((policy) => `'${policy.name}'`).join(', ')} policies`
          : ''
      } 
      `
      });
    } else {
      setShowModal(!showModal);
      formModel.projectNames = [];
    }
  };

  const confirmDelete = async () => {
    setShowModal(!showModal);
    setSecrets([]);
    await onFormUpdate(true); // delete process
    deletionConfirmed();
  };

  const deletionConfirmed = (evt) => {
    if (!connectionName) {
      return;
    }
    setViewState(globalViewStates.EXECUTING);
    deleteConnection(connectionName).then(
      () => {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: `${connectionName} was deleted.`
        });
      },
      (err) => {
        console.log(err);
        setViewState(globalViewStates.DONE);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
      }
    );
  };

  /********************   BEARER TOKEN   *********************/

  //Change bearer token
  const onChangeBearerToken = (value, field) => {
    if (field === 'name') {
      setFormModelBearerToken({
        ...formModelBearerToken,
        [field]: value
      });
    } else {
      setFormModelBearerToken({
        ...formModelBearerToken,
        bearerInfo: {
          ...formModelBearerToken.bearerInfo,
          [field]: value
        }
      });
    }
  };

  // clear form
  const clearFormBearerToken = () => {
    setFormErrorModelBearerToken(initialBearerFormModel);
  };

  const onFormSubmitSecretBearerToken = (e) => {
    e.preventDefault();

    const obj = {
      name: formModelBearerToken.name.trim() === '',
      bearerToken: formModelBearerToken.bearerInfo.bearerToken.trim() === ''
    };
    setFormErrorModelApiKey(obj);
    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      onFormSaveSecretBearerToken();
    }
  };

  const onFormSaveSecretBearerToken = async (evt) => {
    // evt.preventDefault();

    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await secretExists(formModelBearerToken.name);

      if (!success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There’s an existing secret with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
      const { success: successAdd } = await addNewSecret(formModelBearerToken);

      if (!successAdd) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      setViewState(globalViewStates.IDLE);
      toast({
        title: 'Successfully',
        description: 'New secret added.'
      });

      fetchKeys(formModel.name).then(({ success, payload }) => {
        if (!success) {
          return;
        }

        const { secrets } = payload;
        const secretBEARER = secrets.filter((item) => item.kind === 'bearer-token');
        setSecrets(secretBEARER);
        setFormModelBearerToken(initialBearerFormModel);
      });

      setCreateOrSelectExisting('SELECT');

      // Check if credential exists by getting credential by name.
      // add credential, go back to listing.
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
    }
  };

  //secret control
  useEffect(() => {
    if (selectedAuthenticationType === authTypes.NONE) {
      setFormModel({
        ...formModel,
        secret: ''
      });
    }
  }, [selectedAuthenticationType]);

  //REST TOKEN AUTH
  const onFormSubmitSecretRestTokenAuth = (type, e) => {
    // e.preventDefault();

    const obj = {
      name: formModelRestTokenAuth.name.trim() === '',
      userName: formModelRestTokenAuth.credentialInfo.username.trim() === '',
      password: formModelRestTokenAuth.credentialInfo.password.trim() === ''
    };

    setFormErrorModelRestToken(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      onFormSaveSecretRestTokenAuth();
    }
  };

  const onFormSaveSecretRestTokenAuth = async (evt) => {
    // evt.preventDefault();

    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await secretExists(formModelRestTokenAuth.name);

      if (!success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There’s an existing secret with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
      const { success: successAdd } = await addNewSecret(formModelRestTokenAuth);

      if (!successAdd) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      setViewState(globalViewStates.IDLE);
      toast({
        title: 'Successfully',
        description: 'new rest token auth secret added.'
      });
      fetchKeys(formModel.name).then(({ success, payload }) => {
        if (!success) {
          return;
        }

        const { secrets } = payload;
        const secretsREST = secrets.filter((item) => item.kind === 'rest-token-auth');
        setSecrets(secretsREST);
      });

      setCreateOrSelectExisting('SELECT');

      // Check if credential exists by getting credential by name.
      // add credential, go back to listing.
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
    }
  };

  // clear form
  const clearFormRestTokenAuth = () => {
    setFormModelRestTokenAuth(InitialRestTokenAuthFormModel);
  };

  //Change Input rest token auth
  const onChangeInputRestToken = (value, field) => {
    if (field === 'name') {
      setFormModelRestTokenAuth({
        ...formModelRestTokenAuth,
        [field]: value
      });
    } else {
      setFormModelRestTokenAuth({
        ...formModelRestTokenAuth,
        credentialInfo: {
          ...formModelRestTokenAuth.credentialInfo,
          [field]: value
        }
      });
    }
  };

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/connections" />;
  }

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold">
              {connectionName ? 'UPDATE REST CONNECTION' : 'NEW REST CONNECTION'}
            </h2>

            <div className="flex flex-col gap-6">
              {/* Connection Name  */}
              <div>
                <label htmlFor="name" className="label-main">
                  Connection Name *
                </label>

                <input
                  name="name"
                  type="text"
                  required
                  disabled={connectionName}
                  readOnly={connectionName}
                  maxLength="250"
                  placeholder=""
                  className={`${connectionName ? 'input-main-disabled' : 'input-main'} ${
                    formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                  }`}
                  value={connectionName ? connectionName : formModel.name}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'name');
                  }}
                />
                <span className="label-second">
                  Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                </span>

                {formErrorModel.name && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required and name is
                  </span>
                )}

                {formErrorModel.uniqueName && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>

              {/*  Description  */}
              <div>
                <label htmlFor="description" className="label-main">
                  Description (optional)
                </label>

                <textarea
                  rows={2}
                  name="description"
                  id="description"
                  className="input-main"
                  // defaultValue={''}
                  value={formModel.description}
                  maxLength={125}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'description');
                  }}
                />
              </div>

              {/* Base Url */}
              <div>
                <label htmlFor="http" className="label-main">
                  Base URL *
                </label>

                <div className="relative">
                  <div className="absolute inset-y-0 left-0 top-[2px]">
                    <select
                      name="http"
                      className="input-main !border-0 "
                      value={formModelUrl.http}
                      onChange={(e) => {
                        onChangeInputUrl(e.target.value, 'http');
                      }}
                    >
                      <option value="https://">https://</option>
                      <option value="http://">http://</option>
                    </select>
                  </div>

                  <input
                    type="text"
                    name="url"
                    className="input-main !pl-24"
                    placeholder="example.com"
                    value={formModelUrl.url}
                    onChange={(e) => {
                      onChangeInputUrl(e.target.value, 'url');
                    }}
                  />

                  <span className="label-second">URL must begin with https:// or http://.</span>

                  {formErrorModel.baseUrl && (
                    <span className="label-second my-1 block !text-red-500">
                      This area is required!
                    </span>
                  )}
                </div>
              </div>

              {/*  Headers */}
              <div>
                <label
                  htmlFor="http"
                  className={`
                label-main 
                ${
                  formModel.restConnectionInfo.headers.length === 0
                    ? 'mb-1 block border-b pb-1 dark:border-gray-700'
                    : ''
                }
                `}
                >
                  Headers
                </label>

                <div className="relative">
                  {formModel.restConnectionInfo.headers &&
                    formModel.restConnectionInfo.headers.map((item, index) => {
                      return (
                        <div className="inset-y-0 left-0 top-[2px] flex" key={index}>
                          <input
                            name="key"
                            type="text"
                            className="input-main !rounded-r-none"
                            placeholder="key"
                            value={item.key}
                            onChange={(e) => {
                              onChangeHeaders(e.target.value, 'key', index);
                            }}
                          />
                          <input
                            name="value"
                            type="text"
                            className="input-main !rounded-l-none !rounded-r-none"
                            placeholder="value"
                            value={item.value}
                            onChange={(e) => {
                              onChangeHeaders(e.target.value, 'value', index);
                            }}
                          />
                          <button
                            type="text"
                            className="btn-delete !rounded-l-none"
                            onClick={deleteHeader.bind(this, item)}
                          >
                            <BiX />
                          </button>
                        </div>
                      );
                    })}
                  <div className="items-top flex justify-between text-xs ">
                    {formErrorModel.headers ? (
                      <span className="label-second my-1 block !text-red-500">
                        This area is required!
                      </span>
                    ) : (
                      <span className="label-second block"> </span>
                    )}

                    <button
                      type="text"
                      className="mt-1 flex items-center rounded-md border p-1.5 px-2 text-blue-500 hover:bg-blue-300 hover:text-blue-900 dark:border-gray-700"
                      onClick={addHeader}
                    >
                      <BiPlus className="mr-1 !text-base" /> Add New
                    </button>
                  </div>
                </div>
              </div>

              {/* Authentication Type */}
              <div>
                <label htmlFor="Authentication" className="label-main">
                  Authorization Type
                </label>

                <select
                  id="authentication"
                  name="Authentication"
                  className="input-main "
                  value={selectedAuthenticationType}
                  onChange={(e) => {
                    onChangeAuthenticationType(e.target.value, 'authenticationType');
                  }}
                >
                  {authenticationTypes.map((authType, index) => {
                    return (
                      <option key={index} value={authType}>
                        {authType}
                      </option>
                    );
                  })}
                </select>
                {selectedAuthenticationType !== authTypes.NONE ? (
                  <div className="mt-3">
                    {/* <label htmlFor="name" className="label-main my-3">
                    Select or Create
                  </label> */}

                    {createOrSelectExistingTypes.map((item, index) => {
                      return (
                        <div className="ml-1 mt-2 flex items-center" key={index}>
                          <input
                            type="radio"
                            name="createOrSelectExisting"
                            checked={createOrSelectExisting === item.typeName}
                            value={item.typeName}
                            onChange={(e) => {
                              onChangeInputCreateOrSelectExisting(
                                e.target.value,
                                'createOrSelectExisting'
                              );
                            }}
                          />

                          <button
                            type="button"
                            htmlFor="createOrSelectExisting"
                            className="label-main ml-2"
                            onClick={(e) => {
                              onChangeInputCreateOrSelectExisting(
                                item.typeName,
                                'createOrSelectExisting'
                              );
                            }}
                          >
                            {item.labelName}
                          </button>
                        </div>
                      );
                    })}

                    {selectedAuthenticationType === authTypes.API_KEY ? (
                      <div className="mt-5">
                        <label htmlFor="Authentication" className="label-main">
                          API key location
                        </label>

                        <select
                          name="apiKeyLocation"
                          className="input-main "
                          defaultValue={formModel.restConnectionInfo.apiKeyLocation}
                          onChange={(e) => {
                            onChangeApiKeyLocation(e.target.value, 'apiKeyLocation');
                          }}
                        >
                          <option value="header">Header</option>
                          <option value="query-param">Query Param</option>
                        </select>

                        {formModel.restConnectionInfo.apiKeyLocation === 'header' ? (
                          <span className="label-second">Your API Key will be sent in request</span>
                        ) : (
                          <span className="label-second">
                            Your API Key will be part of URL query
                          </span>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}

                {selectedAuthenticationType === authTypes.REST_TOKEN_AUTH ? (
                  <div className="mt-5">
                    <label htmlFor="tokenAuthEndpointUrl" className="label-main">
                      Token Auth Endpoint URL
                    </label>

                    <input
                      name="tokenAuthEndpointUrl"
                      type="text"
                      required
                      maxLength="250"
                      placeholder=""
                      className="input-main"
                      value={formModel.restConnectionInfo.tokenAuthEndpointUrl}
                      onChange={(e) => {
                        onChangeTokenAuthEndpointUrl(e.target.value, 'tokenAuthEndpointUrl');
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
                {selectedAuthenticationType === authTypes.CMS_CREDENTIAL ? (
                  <div className="mt-5">
                    <label htmlFor="tokenAuthEndpointUrl" className="label-main">
                      Token Auth Endpoint URL
                    </label>

                    <input
                      name="tokenAuthEndpointUrl"
                      type="text"
                      required
                      maxLength="250"
                      placeholder=""
                      className="input-main"
                      value={formModel.restConnectionInfo.tokenAuthEndpointUrl}
                      onChange={(e) => {
                        onChangeTokenAuthEndpointUrl(e.target.value, 'tokenAuthEndpointUrl');
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>

              {/* Select existing authentication */}
              {selectedAuthenticationType !== authTypes.NONE &&
              createOrSelectExisting === createOrSelectExistingChoosing.SELECT ? (
                <div>
                  <label htmlFor="Authentication" className="label-main">
                    Authentication
                  </label>

                  <select
                    name="secret"
                    className="input-main"
                    // value={formModel.secrets}
                    defaultValue={formModel.secret}
                    onChange={(e) => {
                      onChangeInput(e.target.value, 'secret');
                    }}
                  >
                    {secrets.length === 0 ? (
                      <option>There is no authentication record.</option>
                    ) : (
                      <option value="">select an existing key</option>
                    )}

                    {secrets?.map((key) => {
                      return (
                        <option value={key.name} key={key.name}>
                          {key.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                ''
              )}

              {selectedAuthenticationType !== authTypes.NONE &&
              createOrSelectExisting === createOrSelectExistingChoosing.CREATE ? (
                <>
                  {selectedAuthenticationType === authTypes.BASIC_AUTH ? (
                    <div className="relative mb-4 flex flex-col gap-3 rounded border p-5 dark:border-gray-700">
                      <h3 className="absolute -top-2.5 left-1/2 -translate-x-1/2 bg-white">
                        BASIC AUTH
                      </h3>
                      <BasicInformation
                        content={`This is the username and password for your HTTP Basic Authentication flows`}
                      />
                      <div>
                        <label className="label-main">Name *</label>

                        <input
                          name="name"
                          placeholder=""
                          maxLength="250"
                          required
                          className="input-main"
                          value={formModelCredential.name}
                          onChange={(e) => {
                            onChangeInputCredential(e.target.value, 'name');
                          }}
                        />
                        <span className="label-second">A friendly name for your credentials</span>

                        {formErrorModelCredential.name && (
                          <span className="my-1 block text-sm !text-red-500">
                            This area is required!
                          </span>
                        )}
                      </div>

                      <div>
                        <label className="label-main" htmlFor="username">
                          Username
                        </label>

                        <input
                          name="username"
                          type="text"
                          maxLength="250"
                          placeholder=""
                          className="input-main"
                          value={formModelCredential.credentialInfo.username}
                          onChange={(e) => {
                            onChangeInputCredential(e.target.value, 'username');
                          }}
                        />

                        {formErrorModelCredential.userName && (
                          <span className="my-1 block text-sm !text-red-500">
                            This area is required!
                          </span>
                        )}
                      </div>

                      <div>
                        <label className="label-main">Password</label>

                        <input
                          name="password"
                          type="password"
                          placeholder=""
                          className="input-main"
                          value={formModelCredential.credentialInfo.password}
                          onChange={(e) => {
                            onChangeInputCredential(e.target.value, 'password');
                          }}
                        />

                        {formErrorModelCredential.password && (
                          <span className="my-1 block text-sm !text-red-500">
                            This area is required!
                          </span>
                        )}
                      </div>

                      <div className="flex justify-center">
                        <button type="button" className="btn-danger" onClick={clearFormCredential}>
                          Clear
                        </button>

                        <button
                          type="button"
                          className="btn-success"
                          onClick={onFormSubmitSecretBasicAuth}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {selectedAuthenticationType === authTypes.REST_TOKEN_AUTH ? (
                    <div className="relative mb-4 flex flex-col gap-3 rounded border p-5 dark:border-gray-700">
                      <h3 className="absolute -top-2.5 left-1/2 -translate-x-1/2 bg-white">
                        REST TOKEN AUTH
                      </h3>
                      <BasicInformation
                        content={`This is a two step authentication flow mechanism. We will first 
      make an HTTP Basic Auth request with these username/password credentials 
      and then use the token from the response as the HTTP Bearer token for 
      subsequent requests. The Authentication URL will be provided as part of connection settings.`}
                      />
                      <div>
                        <label className="label-main">Name *</label>

                        <input
                          name="name"
                          placeholder=""
                          maxLength="250"
                          required
                          className="input-main"
                          value={formModelRestTokenAuth.name}
                          onChange={(e) => {
                            onChangeInputRestToken(e.target.value, 'name');
                          }}
                        />
                        <span className="label-second">A friendly name for your credentials</span>

                        {formErrorModelRestToken.name && (
                          <span className="my-1 block text-sm !text-red-500">
                            This area is required!
                          </span>
                        )}
                      </div>

                      <div>
                        <label htmlFor="username" className="label-main !flex">
                          <span>Username *</span>
                          <span className="group flex items-center">
                            <BsFillInfoCircleFill className="mx-2 cursor-pointer text-blue-500" />
                            <span className="hidden transition-all group-hover:block">
                              This is your API Username
                            </span>
                          </span>
                        </label>

                        <input
                          name="username"
                          type="text"
                          maxLength="250"
                          placeholder=""
                          className="input-main"
                          value={formModelRestTokenAuth.credentialInfo.username}
                          onChange={(e) => {
                            onChangeInputRestToken(e.target.value, 'username');
                          }}
                        />

                        {formErrorModelRestToken.userName && (
                          <span className="my-1 block text-sm !text-red-500">
                            This area is required!
                          </span>
                        )}
                      </div>

                      <div>
                        <label htmlFor="name" className="label-main !flex">
                          <span>Password *</span>
                          <span className="group flex items-center">
                            <BsFillInfoCircleFill className="mx-2 cursor-pointer text-blue-500" />
                            <span className="hidden transition-all group-hover:block ">
                              This is your API Password
                            </span>
                          </span>
                        </label>

                        <input
                          name="password"
                          type="password"
                          placeholder=""
                          className="input-main"
                          value={formModelRestTokenAuth.credentialInfo.password}
                          onChange={(e) => {
                            onChangeInputRestToken(e.target.value, 'password');
                          }}
                        />

                        {formErrorModelRestToken.password && (
                          <span className="my-1 block text-sm !text-red-500">
                            This area is required!
                          </span>
                        )}
                      </div>

                      <div className="flex justify-center">
                        <button
                          type="button"
                          className="btn-danger"
                          onClick={clearFormRestTokenAuth}
                        >
                          Clear
                        </button>

                        <button
                          type="button"
                          className="btn-success"
                          onClick={(e) => onFormSubmit('SAVE', e)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {selectedAuthenticationType === authTypes.API_KEY ? (
                    <div className="flex flex-col gap-6">
                      <div className="relative mb-4 flex flex-col gap-3 rounded border p-5 dark:border-gray-700">
                        <h3 className="absolute -top-2.5 left-1/2 -translate-x-1/2"> API KEY </h3>

                        <div className="mb-4 rounded-md bg-blue-50 p-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <BsFillInfoCircleFill className="h-5 w-5 text-blue-500" />
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                              <p className="text-sm leading-5 text-blue-700">
                                This is the API Key and Value that you want to add to your header or
                                your query parameters. If you select Header as the location, a
                                custom HTTP Header named <b>"Key: Value"</b> will be added to the
                                list. If you select Query Parameters as the location, then{' '}
                                <b>&Key=Value</b> will be added to your query.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div>
                          <label htmlFor="name" className="label-main">
                            Name *
                          </label>

                          <input
                            type="text"
                            name="name"
                            className="input-main"
                            value={formModelApiKey.name}
                            onChange={(e) => {
                              onChangeApiKey(e.target.value, 'name');
                            }}
                          />

                          {formErrorModelApiKey.name && (
                            <span className="my-1 block text-sm !text-red-500">
                              This area is required!
                            </span>
                          )}
                        </div>

                        <div>
                          <label htmlFor="apiKeyName" className="label-main">
                            Key *
                          </label>

                          <input
                            name="apiKeyName"
                            type="text"
                            placeholder=""
                            className="input-main"
                            value={formModelApiKey.restInfo.apiKeyName}
                            onChange={(e) => {
                              onChangeApiKey(e.target.value, 'apiKeyName');
                            }}
                          />

                          {formErrorModelApiKey.apiKeyName && (
                            <span className="my-1 block text-sm !text-red-500">
                              This area is required!
                            </span>
                          )}
                        </div>

                        <div>
                          <label htmlFor="apiKeyValue" className="label-main">
                            Value *
                          </label>

                          <input
                            name="apiKeyValue"
                            type="text"
                            placeholder=""
                            className="input-main"
                            value={formModelApiKey.restInfo.apiKeyValue}
                            onChange={(e) => {
                              onChangeApiKey(e.target.value, 'apiKeyValue');
                            }}
                          />

                          {formErrorModelApiKey.apiKeyValue && (
                            <span className="my-1 block text-sm !text-red-500">
                              This area is required!
                            </span>
                          )}
                        </div>

                        <div className="flex justify-center">
                          <button type="button" className="btn-danger" onClick={clearFormApiKey}>
                            Clear
                          </button>

                          <button
                            type="button"
                            className="btn-success"
                            onClick={onFormSubmitSecretApiKey}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {selectedAuthenticationType === authTypes.CMS_CREDENTIAL ? (
                    <KeyNewCms isInnerForm={true} setIsInnerFormSuccess={setIsInnerFormSuccess} />
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}

              {selectedAuthenticationType === authTypes.BEARER_TOKEN &&
              createOrSelectExisting === createOrSelectExistingChoosing.CREATE ? (
                <div className="flex flex-col gap-6">
                  <div className="relative mb-4 flex flex-col gap-3 rounded border p-5 dark:border-gray-700">
                    <h3 className="absolute -top-2.5 left-1/2 -translate-x-1/2">
                      {' '}
                      NEW BEARER TOKEN{' '}
                    </h3>

                    <div>
                      <label htmlFor="name" className="label-main">
                        Name *
                      </label>

                      <input
                        type="text"
                        name="name"
                        className="input-main"
                        value={formModelBearerToken.name}
                        onChange={(e) => {
                          onChangeBearerToken(e.target.value, 'name');
                        }}
                      />

                      {formErrorModelBearerToken.name && (
                        <span className="my-1 block text-sm !text-red-500">
                          This area is required!
                        </span>
                      )}
                    </div>

                    <div>
                      <label htmlFor="bearerToken" className="label-main">
                        Token *
                      </label>

                      <input
                        name="bearerToken"
                        type="text"
                        placeholder=""
                        className="input-main"
                        value={formModelBearerToken.bearerInfo.bearerToken}
                        onChange={(e) => {
                          onChangeBearerToken(e.target.value, 'bearerToken');
                        }}
                      />

                      {formErrorModelBearerToken.bearerToken && (
                        <span className="my-1 block text-sm !text-red-500">
                          This area is required!
                        </span>
                      )}
                    </div>

                    <div className="flex justify-center">
                      <button type="button" className="btn-danger" onClick={clearFormBearerToken}>
                        Clear
                      </button>

                      <button
                        type="button"
                        className="btn-success"
                        onClick={onFormSubmitSecretBearerToken}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {connectionName && (
                <div>
                  <h2 className="mb-2 text-xl font-extrabold">Environment Variables</h2>
                  <ConnectionEnvironment connectionName={connectionName} />
                </div>
              )}
              {userState.userRole.role !== userRoles.READ_ONLY && connectionName && (
                <>
                  <h2 className="py-6 text-xl font-extrabold">Danger Zone</h2>

                  <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                    <p className="label-second">
                      By clicking the button below you delete this connection. This action is{' '}
                      <strong>irreversible</strong>.
                    </p>
                    <p className="label-second mb-4">
                      You can still re-use this connection's secrets and commands in other
                      connections.
                    </p>
                    <button
                      type="button"
                      onClick={onDeleteClick}
                      className={` text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                    >
                      I understand the consequences. Delete this connection
                    </button>
                  </div>
                </>
              )}

              {userState.userRole.role !== userRoles.READ_ONLY && (
                <div className="flex justify-center">
                  <button type="button" className="btn-danger" onClick={clearForm}>
                    Clear
                  </button>
                  {connectionName ? (
                    <button
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit('UPDATE', e)}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      data-id="rest-connection-form-save"
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit('SAVE', e)}
                    >
                      Save
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this connection?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConnectionNewRest;
