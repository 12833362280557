import { DropdownMenu, DropdownMenuTrigger } from 'components/ui/dropdown-menu';
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem, useSidebar } from 'components/ui/sidebar';
import desktopLogo from 'assets/img/opsbeacon-dark-logo.png';
import appIcon from 'assets/img/app-icon.png';

export function NavHeader() {
  const sidebar = useSidebar();
  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton size="md" className="hover:bg-transparent">
              <img src={sidebar.open ?desktopLogo : appIcon} className={!sidebar.open && 'h-6 w-6'} alt="Logo" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
