/**
 *   _|_
 *  /@-@\ Copyright © OpsBeacon, Inc.
 *  \ - /    All rights reserved.
 *   };{
 */

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'App';
import * as serviceWorker from 'serviceWorker';

import './assets/css/tailwind.css';

import { Provider } from 'react-redux';
import { store } from 'redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// Change to register for production!
// Also have a means to remotely invalidate serviceworker, like checking a uncached /version.txt
// or something.
// console.log(process.env.NODE_ENV);

serviceWorker.unregister();
